/*
 * @Description:停车场运营管理系统 车辆管理 黑白名单 whiteListCar
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:18:28
 * @LastEditors: GeChang
 */
import request from "@/utils/request";

// const prefix = "/"

const prefix = "/operate"
export function queryListByPage (param) {
  return request({
    url: prefix + "/BlackWhiteListController/queryWhiteList",
    method: "post",
    data: param
  });
}
// 黑名单查询
export function queryBlackListByPage (param) {
  return request({
    url: prefix + "/BlackWhiteListController/queryBlackList",
    method: "post",
    data: param
  });
}
//黑白名单新增接口
export function blackWhiteInsert (param) {
  return request({
    url: prefix + "/BlackWhiteListController/insert",
    method: "post",
    data: param
  });
}
//黑白名单删除
export function blackWhiteDelete (param) {
  return request({
    url: prefix + "/BlackWhiteListController/deleteBatch",
    method: "post",
    data: param
  });
}
//黑白名单修改
export function blackWhiteUpdate (param) {
  return request({
    url: prefix + "/BlackWhiteListController/update",
    method: "post",
    data: param
  });
}
//启用接口
export function enable (param) {
  return request({
    url: prefix + "/BlackWhiteListController/enable",
    method: "post",
    data: param
  });
}
//停用接口
export function disable (param) {
  return request({
    url: prefix + "/BlackWhiteListController/disable",
    method: "post",
    data: param
  });
}
