/*
 * @Description: 系统菜单管理 systemMenuManagement.js
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-03 16:41:56
 * @LastEditors: yanxiao
 */

import request from "@/utils/request";

const prefix = '/login'
// 侧边导航栏 根据登录的用户查询数据:无分页
export function queryListByUser (param) {
  return request({
    url: prefix + "/MenuInfoController/queryListByUser",
    method: "post",
    data: param
  });
}

// 菜单信息查询:分页查询数据
export function queryListByPage (param) {
  return request({
    url: prefix + "/MenuInfoController/queryListByPage",
    method: "post",
    data: param
  });
}

// 菜单信息查询:无分页
export function queryList (param) {
  return request({
    url: prefix + "/MenuInfoController/queryList",
    method: "post",
    data: param
  });
}
//  新：菜单信息查询:无分页
export function queryMenuList (param) {
  return request({
    url: prefix + "/MenuInfoController/queryMenuList ",
    method: "post",
    data: param
  });
}

// 新增一条数据
export function menuInsert (param) {
  return request({
    url: prefix + "/MenuInfoController/insert",
    method: "post",
    data: param
  });
}
// 删除一条数据
export function menuDelete (param) {
  return request({
    url: prefix + "/MenuInfoController/delete",
    method: "post",
    data: param
  });
}
// 修改一条数据
export function menuUpdate (param) {
  return request({
    url: prefix + "/MenuInfoController/update",
    method: "post",
    data: param
  });
}

//查询用户按钮权限接口 localhost:10001/login/MenuInfoController/queryButtonListByUser
export function queryButtonListByUser (param) {
  return request({
    url: prefix + "/MenuInfoController/queryButtonListByUser",
    method: "post",
    data: param
  });
}