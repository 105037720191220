/*
 * @Description:车辆用户管理系统 钱包消费统计 cuWalletStatisticManagement
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-06 09:52:41
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/"

export function demo (param) {
  return request({
    url: prefix + "/demo",
    method: "post",
    data: param
  });
}
