/*
 * @Description:运维管理系统 设备管理 ETC etcDev
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 21:58:30
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/"

export function demo (param) {
  return request({
    url: prefix + "/demo",
    method: "post",
    data: param
  });
}
