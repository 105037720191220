/*
 * @Description:大数据分析 设备指标分析 equipmentIndexAnalysis
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:26:39
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/analysis"
// 查询各设备故障产生率
export function queryEquipmentFailureRate (param) {
  return request({
    url: prefix + "/equipmentAnalysis/queryEquipmentFailureRate",
    method: "post",
    data: param
  });
}
// 某种设备故障所属厂商分布
export function queryEquipmentVendorDistribution (param) {
  return request({
    url: prefix + "/equipmentAnalysis/queryEquipmentVendorDistribution",
    method: "post",
    data: param
  });
}
// 查询各设备运维工单响应率 
export function queryEquipmentResponseRate (param) {
  return request({
    url: prefix + "/equipmentAnalysis/queryEquipmentResponseRate",
    method: "post",
    data: param
  });
}

// 查询某种设备运维单位响应率
export function queryEquipmentMaintenanceRate (param) {
  return request({
    url: prefix + "/equipmentAnalysis/queryEquipmentMaintenanceRate",
    method: "post",
    data: param
  });
}
// 各设备运维工单占比
export function queryEquipmentOrderRate (param) {
  return request({
    url: prefix + "/equipmentAnalysis/queryEquipmentOrderRate",
    method: "post",
    data: param
  });
}
// 某种设备运维工单4个状态统计情况
export function queryEquipmentStatusRate (param) {
  return request({
    url: prefix + "/equipmentAnalysis/queryEquipmentStatusRate",
    method: "post",
    data: param
  });
}
// 各街道设备故障统计TOP5/各街道工单统计TOP5
export function streetEquipmentFailureList (param) {
  return request({
    url: prefix + "/equipmentAnalysis/streetEquipmentFailureList",
    method: "post",
    data: param
  });
}