/*
 * @Description:客服用户服务管理 信息管理 帮助中心管理 helpCenterManage
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-05-19 13:57:18
 * @LastEditors: yanxiao
 */
import request from "@/utils/request";

const prefix = "/operate"

// 新增
export function addSupport (param) {
  return request({
    url: prefix + "/support/addSupport",
    method: "post",
    data: param
  });
}

// 删除
export function deleteSupport (param) {
  return request({
    url: prefix + "/support/deleteSupport",
    method: "post",
    data: param
  });
}

// 修改
export function updateSupport (param) {
  return request({
    url: prefix + "/support/updateSupport",
    method: "post",
    data: param
  });
}

// 查询
export function querySupportList (param) {
  return request({
    url: prefix + "/support/querySupportList",
    method: "post",
    data: param
  });
}

// 发布
export function publish (param) {
  return request({
    url: prefix + "/support/publish",
    method: "post",
    data: param
  });
}

// 停止发布
export function stopPublish (param) {
  return request({
    url: prefix + "/support/stopPublish",
    method: "post",
    data: param
  });
}
