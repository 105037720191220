/*
 * @Description:运维管理 运维管理 运维工单管理 绑定设备清单 operateUnitDev
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-08-16 12:00:23
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/operate"
// 查询配置
export function queryDeviceConfig (param) {
  return request({
    url: prefix + "/maintenance/queryDeviceConfig",
    method: "post",
    data: param
  });
}
// 查询该单位有几种设备
export function queryDeviceTypeList (param) {
  return request({
    url: prefix + "/maintenance/queryDeviceTypeList",
    method: "post",
    data: param
  });
}
// 新增运维关系
export function bindMaintenanceDevice (param) {
  return request({
    url: prefix + "/maintenance/bindMaintenanceDevice",
    method: "post",
    data: param
  });
}
//  解绑运维关系
export function unBindMaintenanceDevice (param) {
  return request({
    url: prefix + "/maintenance/unBindMaintenanceDevice",
    method: "post",
    data: param
  });
}
