/*
 * @Description:财务综合管理 财务管理 支付对账 onroadPatternDetails
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-06-19 14:41:17
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/order"

export function list (param) {
  return request({
    url: prefix + "/checkDetail/list",
    method: "post",
    data: param
  });
}
