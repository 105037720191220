/*
 * @Description:路内停车管理系统 运营管理 实时停车 realtimeParking
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:13:09
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/operate"

//实时停车查询
export function realTimeParking (param) {
  return request({
    url: prefix + "/parkRecord/realTimeParking",
    method: "post",
    data: param
  });
}