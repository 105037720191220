/*
 * @Description:系统管理 系统用户管理 systemUserManagement
 * @Author: Li bin
 * @Github: https://github.com/hzylyh
 * @Date: 2021-03-24 17:14:58
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/login"
//系统用户管理分页查询
export function systemManQuery (param) {
  return request({
    url: prefix + "/AccountInfoController/queryListByPage",
    method: "post",
    data: param
  });
}
//系统用户管理新增
export function systemInsert (param) {
  return request({
    url: prefix + "/AccountInfoController/insert",
    method: "post",
    data: param
  });
}
//系统用户管理修改
export function systemUpdate (param) {
  return request({
    url: prefix + "/AccountInfoController/update",
    method: "post",
    data: param
  });
}
//系统用户管理批量修改
export function updateList (param) {
  return request({
    url: prefix + "/AccountInfoController/updateList",
    method: "post",
    data: param
  });
}
//系统用户管理删除
export function systemDelete (param) {
  return request({
    url: prefix + "/AccountInfoController/delete",
    method: "post",
    data: param
  });
}
//查询账户信息，含角色菜单
export function queryUserInfo (param) {
  return request({
    url: prefix + "/AccountInfoController/queryUserInfo",
    method: "post",
    data: param
  });
}
