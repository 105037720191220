/*
 * @Description:停车场运营管理系统 车场基础信息 车位管理 truckSpaceManage
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:32:01
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/"

export function demo (param) {
  return request({
    url: prefix + "/demo",
    method: "post",
    data: param
  });
}
