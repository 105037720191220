/*
 * @Description: 车辆用户管理系统 用户管理 用户信息 cuUserInfo
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-06 10:20:00
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/operate"
// 查询列表
export function queryUserList (param) {
  return request({
    url: prefix + "/user/queryUserList",
    method: "post",
    data: param
  });
}
// 解冻
export function enableUser (param) {
  return request({
    url: prefix + "/user/enableUser",
    method: "post",
    data: param
  });
}
// 冻结
export function disableUser (param) {
  return request({
    url: prefix + "/user/disableUser",
    method: "post",
    data: param
  });
}