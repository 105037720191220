/*
 * @Description:财务管理系统 开票记录管理 重新开票  reInvoiceRecord
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-06-03 15:55:58
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/mobile"
// 重新开票
export function queryReInvoiceRecordList (param) {
  return request({
    url: prefix + "/invoice/queryReInvoiceRecordList",
    method: "post",
    data: param
  });
}
// 审核
export function checkReInvoice (param) {
  return request({
    url: prefix + "/invoice/checkReInvoice",
    method: "post",
    data: param
  });
}