/*
 * @Description:停车场运营系统 车场管理 出入口信息 inwardOutwardInfo
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:33:47
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/"

export function demo (param) {
  return request({
    url: prefix + "/demo",
    method: "post",
    data: param
  });
}
