/*
 * @Description:运维管理系统 设备管理 摄像头 cameraDev
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 21:58:14
 * @LastEditors: libin
 */
import request from "@/utils/request";

const prefix = "/operate"
//查
export function queryLotTopCameraList (param) {
  return request({
    url: prefix + "/lotTopCamera/queryLotTopCameraList",
    method: "post",
    data: param
  });
}
//删
export function deleteLotTopCamera (param) {
  return request({
    url: prefix + "/lotTopCamera/deleteLotTopCamera",
    method: "post",
    data: param
  });
}
//改
export function updateLotTopCamera (param) {
  return request({
    url: prefix + "/lotTopCamera/updateLotTopCamera",
    method: "post",
    data: param
  });
}
//增
export function addLotTopCamera (param) {
  return request({
    url: prefix + "/lotTopCamera/addLotTopCamera",
    method: "post",
    data: param
  });
}
