/*
 * @Description: 人行道 运营管理 停车监管 parkingRegulation
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2022-01-17 09:54:49
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/order"
const prefix1 = "/operate"
const prefix2 = "/collection"
const prefix3 = "/bill"
const prefix4 = "/mobile"

// 计费
export function chargeOrder (param) {
  return request({
    url: prefix4 + '/payment/deletePayFlag/' + param.orderSequence,
    method: 'get'
  })
}
// 计费接口
export function charge (param) {
  return request({
    url: prefix3 + '/ChargingController/charge',
    method: 'post',
    data: param
  })
}
// 进场补录
export function editOrderForCome (param) {
  return request({
    url: prefix1 + '/order/editOrderForCome',
    method: 'post',
    data: param
  })
}
// 出口图片
export function getGateRealtimePic (param) {
  return request({
    url: prefix2 + "/gate/getGateRealtimePic" + "/" + param.deviceId,
    method: "get",
  });
}
// 收费二维码
export function qrCodeShoufei (param) {
  return request({
    url: prefix2 + "/gate/qrCode" + "/" + param.deviceId + "/" + param.orderSequence,
    method: "get",
  });
}
// 无牌车收费二维码 
export function noPlateQrCodeShoufei (param) {
  return request({
    url: prefix2 + "/gate/qrCode/noPlate/pay/" + param.deviceId,
    method: "get",
  });
}
// 获取在线订单
export function getOrderOnlineNoPageWithEvidence (param) {
  return request({
    url: prefix + "/inspection/getOrderOnlineNoPageWithEvidence",
    method: "post",
    data: param
  });
}
// 改单
export function editOrder (param) {
  return request({
    url: prefix1 + "/order/editOrder",
    method: "post",
    data: param
  });
}
export function editOrderForLeave (param) {
  return request({
    url: prefix1 + "/order/editOrderForLeave",
    method: "post",
    data: param
  });
}
// 零元放行
export function makeOrderFree (param) {
  return request({
    url: prefix1 + "/order/makeOrderFree",
    method: "post",
    data: param
  });
}
// 根据车牌号车牌颜色停车场编号获取订单详情
export function getOrderByOrderStatus (param) {
  return request({
    url: prefix + "/inspection/getOrderByOrderStatus",
    method: "post",
    data: param
  });
}


// 获取视频播放
export function getVideUrl (param) {
  return request({
    url: prefix1 + "/video/getVideUrl/" + param.deviceId,
    method: "get",
    data: param
  });
}
