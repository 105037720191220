/*
 * @Description:财务管理系统 财务管理 停车收费统计 parkChargeStatistics
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-06-03 15:55:01
 * @LastEditors: xiaosheng 11511201+fxiaosheng@user.noreply.gitee.com
 */
import request from "@/utils/request";

const prefix = "/operate";

export function queryParkingFee(param) {
  return request({
    url: prefix + "/payment/queryParkingFee",
    method: "post",
    data: param,
  });
}

export function queryNewEnergyList(param) {
  return request({
    url: prefix + "/order/queryNewEnergyList",
    method: "post",
    data: param,
  });
}
