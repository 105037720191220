import request from "@/utils/request";

const prefix = "/order"

// 整体收益分析
export function getEarningAnalysisList (param) {
  return request({
    url: prefix + "/operationStatistics/getEarningAnalysisList",
    method: "post",
    data: param
  });
}

// 收费方式收益分析数据查询
export function getChargingMethodAnalysisList (param) {
  return request({
    url: prefix + "/operationStatistics/getChargingMethodAnalysisList",
    method: "post",
    data: param
  });
}

// 路段收益分析数据查询
export function getSectionEarningAnalysisList (param) {
  return request({
    url: prefix + "/operationStatistics/getSectionEarningAnalysisList",
    method: "post",
    data: param
  });
}