/*
 * @Description:路内停车管理系统 绩效管理 考勤记录 attendanceRecord
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:13:32
 * @LastEditors: yanxiao
 */
import request from "@/utils/request";

const prefix = "/operate"

// 考勤查询
export function queryAttendanceList (param) {
  return request({
    url: prefix + "/attendance/queryAttendanceList",
    method: "post",
    data: param
  });
}
