/** @Description: 路内停车管理系统 运营管理 计费规则 chargingRules
 * @Author: hj
 * @Date: 2021-03-25 15:13:00
 * @LastEditTime: 2021-03-25 17:11:35
 */
import request from "@/utils/request";

const prefix = '/bill'


// 计费规则是否收费
export function exchangeChargeStatus (param) {
  return request({
    url: prefix + "/BillingRuleController/exchangeChargeStatus",
    method: "post",
    data: param
  });
}


// 查询表格数据
export function queryBillingRuleList (param) {
  return request({
    url: prefix + "/BillingRuleController/queryBillingRuleList",
    method: "post",
    data: param
  });
}
// 获取计费规则详情
export function queryBillingRuleDetail (param) {
  return request({
    url: prefix + "/BillingRuleController/queryBillingRuleDetail",
    method: "post",
    data: param
  });
}
// 计费规则新增
export function addBillingRule (param) {
  return request({
    url: prefix + "/BillingRuleController/addBillingRule",
    method: "post",
    data: param
  });
}

// 修改计费规则
export function updateBillingRule (param) {
  return request({
    url: prefix + "/BillingRuleController/updateBillingRule",
    method: "post",
    data: param
  });
}
// 删除计费规则
export function delBillingRule (param) {
  return request({
    url: prefix + "/BillingRuleController/delBillingRule",
    method: "post",
    data: param
  });
}