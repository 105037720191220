/*
 * @Description:停车场运营管理系统 停车管理 异常抬杆  abnormalPhenomena
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:26:39
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/operate"

export function listByPage (param) {
  return request({
    url: prefix + "/abnormalLift/listByPage",
    method: "post",
    data: param
  });
}
