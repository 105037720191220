/*
 * @Description: 整体应用状态参数
 * @Author: John Holl
 * @Github: https://github.com/hzylyh
 * @Date: 2021-04-03 10:04:55
 * @LastEditors: zhoucheng
 */


const state = {
  sidebar: {
    opened: localStorage.getItem('sidebarStatus') ? !!+localStorage.getItem('sidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  activeMenu: localStorage.getItem('activeMenu') || '1',
  size: localStorage.getItem('size') || 'medium',
  isNotRefresh: false,
  theme: localStorage.getItem('theme') || 'dark'
}

const mutations = {
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
    if (state.sidebar.opened) {
      localStorage.setItem('sidebarStatus', 1)
    } else {
      localStorage.setItem('sidebarStatus', 0)
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    localStorage.setItem('sidebarStatus', 0)
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  ACTIVE_MENU: (state, active) => {
    state.sidebar.activeMenu =
      localStorage.setItem('activeMenu', active)
  },
  REFRESH_PAGE: (state, active) => {
    state.isNotRefresh = active
  },
  SWITCHTHEME: (state) => {
    state.theme = state.theme == 'dark' ? 'light' : 'dark'
    localStorage.setItem('theme', state.theme)
    window.document.documentElement.setAttribute("data-theme", state.theme);
    // window.location.reload()
  },
}

const actions = {
  toggleSideBar ({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  closeSideBar ({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  activeMenu ({ commit }, { activeMenu }) {
    commit('ACTIVE_MENU', activeMenu)
  },
  refreshPage ({ commit }, { refreshPage }) {
    commit('REFRESH_PAGE', refreshPage)
  },
  switchTheme ({ commit }) {

    commit('SWITCHTHEME')
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
