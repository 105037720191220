/*
 * @Description:停车场运营管理系统 车场基础信息 停车场管理 parkingManage
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:32:25
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/operate"

// 设置运营非运营停车场
export function changeParkStatus (param) {
  return request({
    url: prefix + "/ParkController/changeParkStatus",
    method: "post",
    data: param
  });
}
// 停车场获取当前余位
export function getEmptySpace (param) {
  return request({
    url: prefix + "/ParkSpaceController/getEmptySpace/" + param.parkId,
    method: "post",
    data: param
  });
}

// 停车场余位修改
export function updateEmptySpace (param) {
  return request({
    url: prefix + "/ParkController/updateEmptySpace" + "/" + param.parkId + "/" + param.emptySpace,
    method: "get",
  });
}

// 停车场查询
export function ParkController (param) {
  return request({
    url: prefix + "/ParkController/queryListByPage",
    method: "post",
    data: param
  });
}
//停车场新增
export function newParkController (param) {
  return request({
    url: prefix + "/ParkController/insert",
    method: "post",
    data: param
  });
}
//停车场修改
export function updateParkController (param) {
  return request({
    url: prefix + "/ParkController/update",
    method: "post",
    data: param
  });
}
//停车场删除
export function deleteParkController (param) {
  return request({
    url: prefix + "/ParkController/delete",
    method: "post",
    data: param
  });
}
//停车场批量删除
export function deleteParkControllerBatch (param) {
  return request({
    url: prefix + "/ParkController/deleteBatch",
    method: "post",
    data: param
  });
}
//车位查询
export function ParkSpaceController (param) {
  return request({
    url: prefix + "/ParkSpaceController/queryListByPage",
    method: "post",
    data: param
  });
}
//车位插入
export function insertParkSpaceController (param) {
  return request({
    url: prefix + "/ParkSpaceController/insert",
    method: "post",
    data: param
  });
}
//车位更新
export function updateParkSpaceController (param) {
  return request({
    url: prefix + "/ParkSpaceController/update",
    method: "post",
    data: param
  });
}
//车位删除
export function deleteParkSpaceController (param) {
  return request({
    url: prefix + "/ParkSpaceController/delete",
    method: "post",
    data: param
  });
}
//停车场平面层查询
export function ParkLayerController (param) {
  return request({
    url: prefix + "/ParkLayerController/queryList",
    method: "post",
    data: param
  });
}
// 区域
export function ParkLayerControllerWithPage (param) {
  return request({
    url: prefix + "/ParkLayerController/queryListWithPage",
    method: "post",
    data: param
  });
}
//停车场平面层插入
export function insertParkLayerController (param) {
  return request({
    url: prefix + "/ParkLayerController/insert",
    method: "post",
    data: param
  });
}
//停车场平面层更新
export function updateParkLayerController (param) {
  return request({
    url: prefix + "/ParkLayerController/update",
    method: "post",
    data: param
  });
}
//停车场平面层删除
export function deleteParkLayerController (param) {
  return request({
    url: prefix + "/ParkLayerController/deleteBatch",
    method: "post",
    data: param
  });
}

//车位模板下载
export function ParkSpacemode (param) {
  return request({
    url: prefix + "/ParkSpaceController/downloadTemplate",
    method: "post",
    data: param
  });
}
//车位模板导入
export function ParkSpacejoin (param) {
  return request({
    url: prefix + "/ParkSpaceController/upload",
    method: "post",
    data: param
  });
}

// 停车场查询
export function queryDictWithAuth (param) {
  return request({
    url: prefix + "/dict/queryDictWithAuth",
    method: "post",
    data: param
  });
}
//新增车位
export function insertParkSpace (param) {
  return request({
    url: prefix + "/ParkSpaceController/insertParkSpace",
    method: "post",
    data: param
  });
}
//修改车位
export function updateParkSpace (param) {
  return request({
    url: prefix + "/ParkSpaceController/updateParkSpace",
    method: "post",
    data: param
  });
}
//删除车位
export function delParkSpace (param) {
  return request({
    url: prefix + "/ParkSpaceController/delParkSpace",
    method: "post",
    data: param
  });
}
//停车场导出
export function download (param) {
  return request({
    url: prefix + "/ParkController/download",
    method: "post",
    data: param
  });
}