/*
 * @Author: houjinduo
 * @Date: 2024-05-09 15:52:32
 * @LastEditors: houjinduo
 * @LastEditTime: 2024-05-09 15:56:49
 * @Description: 请填写简介
 */
import request from "@/utils/request";

const prefix = "/operate"

// 退款记录
export function querySmsList (param) {
  return request({
    url: prefix + "/txtMessageController/querySmsList",
    method: "post",
    data: param
  });
}