/*
 * @Description:财务管理系统 财务管理 支付记录 accPayRecord
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-06-03 15:54:44
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/"

export function demo (param) {
  return request({
    url: prefix + "/demo",
    method: "post",
    data: param
  });
}
