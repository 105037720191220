/*
 * @Description: 
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2022-05-12 15:25:29
 * @LastEditors: zhoucheng
 */
import axios from 'axios'
import router from '../plugins/router'
// import store from '@/store'

const service = axios.create({
  baseURL: '/cqparkApi', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 90000 // request timeout
})

service.interceptors.request.use(
  config => {
    config.headers['userToken'] = localStorage.getItem('userToken')
    // config.headers['userToken'] = "cfd98dc613e2429f83e6bf19bc66e5a3"
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.resultCode === undefined) {
      return res
    }
    if (res.resultCode === '3004') {
      router.push('/login');
    }
    if (res.resultCode !== '2000') {
      return Promise.reject(res)
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error)
    return Promise.reject(error)
  }
)
// response interceptor
// service.interceptors.response.use(
//   //所以的响应都会走的拦截
//   response => {
//     const res = response.data
//     if (res.resultCode === '3001' || res.resultCode === '3004') {
//       //3001是未登录/登录失效，所以要跳回登录页面
//       router.push('/login');
//     } else {
//       return res
//     }
//   },
//   error => {
//     console.log('err' + error) // for debug
//     // Message({
//     //   message: error.message,
//     //   type: 'error',
//     //   duration: 5 * 1000
//     // })
//     return Promise.reject(error)
//   }
// )

export default service
