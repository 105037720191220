/*
 * @Description:停车场管理系统 运营管理 业务订单 parkBusinessOrder
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:20:46
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/operate"
const prefix1 = "/order"

// 异常标记
export function abolitionOrder (param) {
  return request({
    url: prefix1 + "/OrderController/abolitionOrder",
    method: "post",
    data: param
  });
}

// 业务订单查询  
export function queryOrderForOutRoad (param) {
  return request({
    url: prefix + "/order/queryOrderForOutRoad",
    method: "post",
    data: param
  });
}

// 业务订单修改
export function editOrder (param) {
  return request({
    url: prefix + "/order/editOrder",
    method: "post",
    data: param
  });
}
