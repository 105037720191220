/*
 * @Description: 运维管理系统 设备管理 道闸设备 barrierGate
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 21:57:53
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/operate"

//道闸机列表查询
export function queryPassagewayGateList (param) {
  return request({
    url: prefix + "/passagewayGate/queryPassagewayGateList",
    method: "post",
    data: param
  });
}
//新增道闸机
export function insertPassagewayGate (param) {
  return request({
    url: prefix + "/passagewayGate/insertPassagewayGate",
    method: "post",
    data: param
  });
}
//删除道闸机
export function delPassagewayGate (param) {
  return request({
    url: prefix + "/passagewayGate/delPassagewayGate",
    method: "post",
    data: param
  });
}
//道闸机修改
export function updatePassagewayGate (param) {
  return request({
    url: prefix + "/passagewayGate/updatePassagewayGate",
    method: "post",
    data: param
  });
}
