/*
 * @Description: 公共接口
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-02 17:47:43
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = '/operate'

// 取证图片
export function getEvidenceByOrderSequence (param) {
  return request({
    url: prefix + "/orderEvidence/getEvidenceByOrderSequence",
    method: "post",
    data: param
  });
}

// 上传图片
export function upFile (param) {
  return request({
    url: prefix + '/file/upFile',
    method: 'post',
    data: param
  })
}

// 下拉框查询
export function queryDict (param) {
  return request({
    url: prefix + "/dict/queryDict",
    method: "post",
    data: param
  });
}

// 停车场查询
export function queryDictWithAuth (param) {
  return request({
    url: prefix + "/dict/queryDictWithAuth",
    method: "post",
    data: param
  });
}

