/*
 * @Description:财务管理系统 开票记录管理 开票记录  finInvoiceRecord
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-06-03 15:55:58
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/operate"
const prefix1 = "/mobile"
// 查询开票记录
export function queryInvoiceRecord (param) {
  return request({
    url: prefix + "/invoice/queryInvoiceRecord",
    method: "post",
    data: param
  });
}
// 查询开票状态
export function getInvoiceAgain (param) {
  return request({
    url: prefix1 + "/invoice/getInvoiceAgain",
    method: "post",
    data: param
  });
}
// 发票反查支付记录
export function getPaymentRecordByInvoice (param) {
  return request({
    url: prefix1 + '/payment/getPaymentRecordByInvoice',
    method: 'post',
    data: param
  })
}


