/*
 * @Author: zhuangfeiyu
 * @Date: 2021-03-07 15:23:39
 * @LastEditTime: 2022-10-27 10:14:56
 * @LastEditors: zhoucheng
 * @Description: In User Settings Edit
 * @FilePath: \chongqing-parking\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import '@/styles/element-variables.scss'
import '@/styles/index.scss'
// import 'element-ui/lib/theme-chalk/index.css'

import VueRouter from 'vue-router'
import router from './plugins/router'
import store from './store'
import VueBus from 'vue-bus'

// 使用echarts
import echarts from 'echarts'
Vue.prototype.$echarts = echarts

// md5加密
import md5 from 'js-md5';
Vue.prototype.$md5 = md5;

// 弹窗拖拽
import './utils/directives.js'

import './utils/date.js'
import deepClone from "./utils/deepClone.js"

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import publicFun from '@/common/js/publicFun.js'
import publicVar from "./utils/publicVar";
import "@/utils/debounce.js"

Vue.use(publicVar)
Vue.prototype.publicFun = publicFun
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.prototype.$deepClone = deepClone

// 封装axios  npm install axios -S
import api from "./axios/index";
Vue.use(api);
import axios from 'axios'
Vue.prototype.$axios = axios

Vue.use(ElementUI, {
  size: 'medium'
})
Vue.use(VueRouter)
Vue.use(VueBus)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')

//监听键盘事件
Vue.prototype.$keyBoard = function (vm, methodName, code) {
  document.onkeydown = function () {
    let key = window.event.keyCode;
    if (key == code) {
      vm[methodName](code); // 触发methodName事件
    }
  };
};