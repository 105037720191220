/*
 * @Description: 系统管理 数据权限管理 dataRightsManagement
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-06 10:34:13
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = '/parkingLogin'

// demo
export function demo (param) {
  return request({
    url: prefix + "/demo",
    method: "post",
    data: param
  });
}
