/*
 * @Description: 车辆用户管理系统 平台车辆统计 cuPlateCarStatistic
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-06 09:58:15
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/"

export function demo (param) {
  return request({
    url: prefix + "/demo",
    method: "post",
    data: param
  });
}
