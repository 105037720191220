/*
 * @Description:路内停车管理系统 运营管理 黑白名单 白名单 onRoadwhiteList
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:10:07
 * @LastEditors: LinFeng
 */
import request from "@/utils/request";

const prefix = "/operate"
//白名单查询
export function queryWhiteListNoAuth (param) {
  return request({
    url: prefix + "/BlackWhiteListController/queryWhiteListNoAuth",
    method: "post",
    data: param
  });
}

//黑白名单新增接口
export function blackWhiteInsert (param) {
  return request({
    url: prefix + "/BlackWhiteListController/insert",
    method: "post",
    data: param
  });
}
//黑白名单删除
export function blackWhiteDelete (param) {
  return request({
    url: prefix + "/BlackWhiteListController/deleteBatch",
    method: "post",
    data: param
  });
}
//黑白名单修改
export function blackWhiteUpdate (param) {
  return request({
    url: prefix + "/BlackWhiteListController/update",
    method: "post",
    data: param
  });
}
//启用接口
export function enable (param) {
  return request({
    url: prefix + "/BlackWhiteListController/enable",
    method: "post",
    data: param
  });
}
//停用接口
export function disable (param) {
  return request({
    url: prefix + "/BlackWhiteListController/disable",
    method: "post",
    data: param
  });
}