/*
 * @Description:车辆用户管理系统 车辆管理 车辆信息 cuCarInfo
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-06 09:45:59
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/operate"

//车辆绑定列表查询
export function queryCarBindList (param) {
  return request({
    url: prefix + "/user/queryCarBindList",
    method: "post",
    data: param
  });
}
//车俩绑定更新
export function updateCarBind (param) {
  return request({
    url: prefix + "/user/updateCarBind",
    method: "post",
    data: param
  });
}

