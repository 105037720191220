/*
 * @Description:人行道停车运营管理 停车管理 场内车辆  siteLayOut
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:21:28
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/operate"

//场内车辆  
export function onSiteVehicles (param) {
  return request({
    url: prefix + "/parkRecord/onSiteVehicles",
    method: "post",
    data: param
  });
}