/*
 * @Description:深拷贝
 * @Author: libin
 * @Github:
 * @Date: 2021-04-26 13:44:47
 * @LastEditors: libin
 */
function deepClone (obj = {}) {
  if (typeof obj !== 'object' || obj == null) {
    return obj;
  }
  let result;
  if (obj instanceof Array) {
    result = [];
  } else {
    result = {};
  }
  for (let key in obj) {
    result[key] = deepClone(obj[key]);
  }
  return result;
}

export default deepClone