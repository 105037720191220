/*
 * @Description:路内停车管理系统 员工管理 收费员管理 chargeManManagement
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:15:18
 * @LastEditors: houjinduo
 */
import request from "@/utils/request";

const prefix = "/operate";
const preFix = "/order";

// 查询巡检员运动轨迹
export function getTrajectory(param) {
  return request({
    url: preFix + "/inspection/getTrajectory",
    method: "post",
    data: param,
  });
}
// 查询巡检员最后上报点位
export function getRealtimeLocationByUserCode(param) {
  return request({
    url: preFix + "/inspection/getRealtimeLocationByUserCode",
    method: "post",
    data: param,
  });
}

// 新增巡检员
export function insertInspection(param) {
  return request({
    url: prefix + "/inspectionController/insertInspection",
    method: "post",
    data: param,
  });
}

// 查询巡检员
export function queryInspectionList(param) {
  return request({
    url: prefix + "/inspectionController/queryInspectionList",
    method: "post",
    data: param,
  });
}

// 修改巡检员
export function updateInspection(param) {
  return request({
    url: prefix + "/inspectionController/updateInspection",
    method: "post",
    data: param,
  });
}

// 删除巡检员
export function deleteInspection(param) {
  return request({
    url: prefix + "/inspectionController/deleteInspection",
    method: "post",
    data: param,
  });
}

// 巡检员和泊位绑定
export function saveInspectionSpace(param) {
  return request({
    url: prefix + "/inspectionSpace/saveInspectionSpace",
    method: "post",
    data: param,
  });
}
// 查询巡检员所有已经绑定的泊位
export function queryInspectionSpaceList(param) {
  return request({
    url: prefix + "/inspectionSpace/queryInspectionSpaceList",
    method: "post",
    data: param,
  });
}
