/*
 * @Description:运维管理 设备管理 巡检车 inspectVehicle
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-06-28 10:48:04
 * @LastEditors: LinFeng
 */
import request from "@/utils/request";

const prefix = "/operate"

export function queryInspectionCarList (param) {
  return request({
    url: prefix + "/inspectionCar/queryInspectionCarList",
    method: "post",
    data: param
  });
}
