/*
 * @Description:运维管理系统 监控管理 设备在线心跳  devOnlineHeartBeat
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:01:20
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/"

export function demo (param) {
  return request({
    url: prefix + "/demo",
    method: "post",
    data: param
  });
}
