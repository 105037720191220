/*
 * @Description: 系统管理-app管理-轮播图管理 slideShowManagement
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-06 10:33:28
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = '/operate'

//demo
// export function demo (param) {
//   return request({
//     url: prefix + "/demo",
//     method: "post",
//     data: param
//   });
// }
//查询
export function queryAdvertiseList (param) {
  return request({
    url: prefix + "/AdvertiseController/queryAdvertiseList",
    method: "post",
    data: param
  });
}

//修改
export function updateAdvertise (param) {
  return request({
    url: prefix + "/AdvertiseController/updateAdvertise",
    method: "post",
    data: param
  });
}

//新增
export function addAdvertise (param) {
  return request({
    url: prefix + "/AdvertiseController/addAdvertise",
    method: "post",
    data: param
  });
}

//删除
export function delAdvertise (param) {
  return request({
    url: prefix + "/AdvertiseController/delAdvertise",
    method: "post",
    data: param
  });
}

//停用
export function disable (param) {
  return request({
    url: prefix + "/AdvertiseController/disable",
    method: "post",
    data: param
  });
}
//启用
export function enable (param) {
  return request({
    url: prefix + "/AdvertiseController/enable",
    method: "post",
    data: param
  });
}