/*
 * @Description: 路内停车管理系统- 路段管理 - 路段泊位 - 泊位管理 onroadRoadParkSpaceNext
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-04-20 11:31:05
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/"

export function demo (param) {
  return request({
    url: prefix + "/demo",
    method: "post",
    data: param
  });
}
