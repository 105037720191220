/*
 * @Description:路内停车管理 运营管理 黑名单告警 blackListWarn
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-08-30 16:34:17
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = '/operate'

// 请求样例
export function queryBlackAlarmRecordList (param) {
  return request({
    url: prefix + "/blackAlarm/queryBlackAlarmRecordList",
    method: "post",
    data: param
  });
}