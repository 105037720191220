/*
 * @Description:停车场运营管理系统 停车管理 异常离场  abnormalDeparture
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:26:39
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/collection"

// 查看详情
export function queryAbnormalCarOutDetail (param) {
  return request({
    url: prefix + "/abnormalCarOut/queryAbnormalCarOutDetail",
    method: "post",
    data: param
  });
}
// 查询异常离场数据
export function queryAbnormalCarOutList (param) {
  return request({
    url: prefix + "/abnormalCarOut/queryAbnormalCarOutList",
    method: "post",
    data: param
  });
}

// 修改异常离场信息
export function updateAbnormalCarOut (param) {
  return request({
    url: prefix + "/abnormalCarOut/updateAbnormalCarOut",
    method: "post",
    data: param
  });
}
// 查询在线订单
export function findOrderByParkIdAndCondition (param) {
  return request({
    url: prefix + "/abnormalCarOut/findOrderByParkIdAndCondition",
    method: "post",
    data: param
  });
}
// 绑定订单
export function bindAbnormalCarOutToOrder (param) {
  return request({
    url: prefix + "/abnormalCarOut/bindAbnormalCarOutToOrder",
    method: "post",
    data: param
  });
}
// 查询用户管理的停车场名称
export function queryParkNameByUser (param) {
  return request({
    url: prefix + "/abnormalCarOut/queryParkNameByUser",
    method: "post",
    data: param
  });
}
