/*
 * @Description:人行道 运营管理 布控告警 parkDeployAlarm
 * @Author: zhoucheng
 * @Github:
 * @Date: 2022-06-16 10:20:46
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/operate"

// 布控告警
export function queryControlVehicleAlarmRecordList (param) {
  return request({
    url: prefix + "/controlVehicleAlarm/queryControlVehicleAlarmRecordList",
    method: "post",
    data: param
  });
}