/*
 * @Description:
 * @Author: libin
 * @Github:
 * @Date: 2021-06-03 14:20:09
 * @LastEditors: zhoucheng
 */

const publicVar = {
  install (Vue) {
    // 把当前的对象挂载到vue的全局
    Vue.prototype.$imgBaseUrl = this.imgBaseUrl
    Vue.prototype.$pdfBaseUrl = this.pdfBaseUrl
    Vue.prototype.$downloadBaseUrl = this.downloadBaseUrl
    Vue.prototype.$downloadOrderBaseUrl = this.downloadOrderBaseUrl
    Vue.prototype.$onlineAddress = this.onlineAddress
  },
  // onlineAddress: 'https://gateway.cqybxm.com',
  onlineAddress: '/cqparkApi/',

  // 图片baseurl
  imgBaseUrl: '/cqparkApi/operate/upload/imgs/',

  // 文件地址
  pdfBaseUrl: '/cqparkApi/operate/invoice/',

  // 导出地址
  downloadBaseUrl: '/cqparkApi/operate/',

  // 退款导出地址
  downloadOrderBaseUrl: '/cqparkApi/order/',

}

export default publicVar