/*
 * @Description:路内停车管理系统 设备管理 地锁设备 floorLock
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 21:58:42
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/operate"
 
const preFix ='/collection'

//查询地锁数据
export function queryGroundLockList (param) {
  return request({
    url: prefix + "/groundLock/queryGroundLockList",
    method: "post",
    data: param
  });
}

//新增地锁数据
export function addGroundLock (param) {
  return request({
    url: prefix + "/groundLock/addGroundLock",
    method: "post",
    data: param
  });
}

//修改地锁数据
export function updateGroundLock (param) {
  return request({
    url: prefix + "/groundLock/updateGroundLock",
    method: "post",
    data: param
  });
}

//删除地锁数据
export function deleteGroundLock (param) {
  return request({
    url: prefix + "/groundLock/deleteGroundLock",
    method: "post",
    data: param
  });
}

// 升锁地锁数据
export function upLock (param) {
  return request({
    url: preFix + "/parkLock/control/up_lock/"+param.deviceId,
    method: "post",
  });
}

// 降锁地锁数据
export function downLock (param) {
  return request({
    url: preFix + "/parkLock/control/down_lock/"+param.deviceId,
    method: "get",
  });
}