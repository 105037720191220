/*
 * @Description:财务综合管理 财务管理 支付订单 onroadPayOrder
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:08:27
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/operate"

// 获取支付订单列表
export function queryPaymentRecord (param) {
  return request({
    url: prefix + "/payment/queryPaymentRecord",
    method: "post",
    data: param
  });
}

// 根据计费规则id获取计费规则详情
export function queryBillingRule (param) {
  return request({
    url: '/bill/BillingRuleController/queryBillingRuleById',
    method: 'post',
    data: param
  })
}