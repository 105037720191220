/*
 * @Description:停车场运营管理系统 车辆管理 会员车辆 vipCar
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:17:52
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

// const prefix = "/"

// export function demo (param) {
//   return request({
//     url: prefix + "/demo",
//     method: "post",
//     data: param
//   });
// }
// const prefix = "/online"
const prefix = '/operate'


//分页查询
export function queryListByPage (param) {
  return request({
    url: prefix + "/MemberCarController/queryListByPage",
    method: "post",
    data: param
  });
}
//插入一条数据
export function vipCarInsert (param) {
  return request({
    url: prefix + "/MemberCarController/insert",
    method: "post",
    data: param
  });
}
//更新一条数据
export function vipCarUpdate (param) {
  return request({
    url: prefix + "/MemberCarController/update",
    method: "post",
    data: param
  });
}
//删除一条数据
export function vipCarDelete (param) {
  return request({
    url: prefix + "/MemberCarController/deleteList",
    method: "post",
    data: param
  });
}
//启用接口
export function enable (param) {
  return request({
    url: prefix + "/MemberCarController/enable",
    method: "post",
    data: param
  });
}
//停用接口
export function disable (param) {
  return request({
    url: prefix + "/MemberCarController/disable",
    method: "post",
    data: param
  });
}

