/*
 * @Description: 车辆用户管理系统 车辆认证 cuCarApprove
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-06 09:43:21
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/"

export function demo (param) {
  return request({
    url: prefix + "/demo",
    method: "post",
    data: param
  });
}
