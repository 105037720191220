/*
 * @Description: 停车场运营管理 运营管理 黑名单告警 parkBlackListWarn
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-08-30 16:40:05
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/"

export function demo (param) {
  return request({
    url: prefix + "/demo",
    method: "post",
    data: param
  });
}
