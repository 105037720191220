/*
 * @Description:人行道停车运营管理 运营管理 欠费记录 parkLackRecords
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-11-27 12:12:12
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/operate"

// 查询表格数据接口
export function queryListByPage (param) {
  return request({
    url: prefix + "/order/getArrangeRecordOut",
    method: "post",
    data: param
  });
}