/*
 * @Description:路内停车管理系统 财务管理 支付对账 onroadPattern
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:08:07
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/order"

export function list (param) {
  return request({
    url: prefix + "/checkBill/list",
    method: "post",
    data: param
  });
}
