/*
 * @Description:路内停车管理系统 数据看板  运营统计 runStatistics
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:06:18
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/analysis"
const prefix1 = "/order"

// 统计分析
export function statisticsListByPageIn (param) {
  return request({
    url: prefix1 + "/operationStatistics/listByPageIn",
    method: "post",
    data: param
  });
}
//统计分析实时
export function statisticsRealtimeIn (param) {
  return request({
    url: prefix1 + "/operationStatistics/realtimeIn",
    method: "post",
    data: param
  });
}

// 运营统计信息查询
export function getOperationStatistics (param) {
  return request({
    url: prefix + "/parkAnalysis/getOperationStatistics",
    method: "post",
    data: param
  });
}
// 车辆收费金额统计
export function getPaymentStatistics (param) {
  return request({
    url: prefix + "/parkAnalysis/getPaymentStatistics",
    method: "post",
    data: param
  });
}
// 欠费补缴占比
export function proportionOfArrears (param) {
  return request({
    url: prefix + "/parkAnalysis/proportionOfArrears",
    method: "post",
    data: param
  });
}
// 缴费类型分布
export function paymentDistribution (param) {
  return request({
    url: prefix + "/parkAnalysis/paymentDistribution",
    method: "post",
    data: param
  });
}
// 今日订单排行
export function orderRanking (param) {
  return request({
    url: prefix + "/parkAnalysis/orderRanking",
    method: "post",
    data: param
  });
}
// 今日补缴排行
export function arrangeMoneyAmountRanking (param) {
  return request({
    url: prefix + "/parkAnalysis/arrangeMoneyAmountRanking",
    method: "post",
    data: param
  });
}
// 今日车次排行
export function trainNumberRanking (param) {
  return request({
    url: prefix + "/parkAnalysis/trainNumberRanking",
    method: "post",
    data: param
  });
}
