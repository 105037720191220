/*
 * @Description:路内停车管理系统 设备管理 卡口监控 bayonetMonitor
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-08-31 10:01:51
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/operate"
const prefix1 = '/collection'

// 抬杆
export function openGate (param) {
  return request({
    url: prefix1 + "/gate/openGateWithException/" + param.deviceId,
    method: "post",
    data: param
  });
}
// 落杆
export function closeGate (param) {
  return request({
    url: prefix1 + "/gate/closeGateWithException/" + param.deviceId,
    method: "post",
    data: param
  });
}



// 查询
export function queryVideoList (param) {
  return request({
    url: prefix + "/video/queryVideoList",
    method: "post",
    data: param
  });
}
// 新增
export function addVideo (param) {
  return request({
    url: prefix + "/video/addVideo",
    method: "post",
    data: param
  });
}
// 修改
export function updateVideo (param) {
  return request({
    url: prefix + "/video/updateVideo",
    method: "post",
    data: param
  });
}
// 删除
export function deleteVideo (param) {
  return request({
    url: prefix + "/video/deleteVideo",
    method: "post",
    data: param
  });
}

