/*
 * @Description:客服用户服务管理 意见反馈管理  意见反馈管理 feedBackInfoManage
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-05-19 13:56:27
 * @LastEditors: libin
 */
import request from "@/utils/request";

const prefix = "/operate"

// 意见反馈查询查询
export function queryFeedBackList (param) {
  return request({
    url: prefix + "/feedback/queryFeedBackList",
    method: "post",
    data: param
  });
}

// 意见反馈查询修改
export function updateFeedBack (param) {
  return request({
    url: prefix + "/feedback/updateFeedBack",
    method: "post",
    data: param
  });
}
// 意见反馈查询删除
export function deleteFeedBack (param) {
  return request({
    url: prefix + "/feedback/deleteFeedBack",
    method: "post",
    data: param
  });
}
// 意见反馈查询

//处理 
export function handleFeedBack (param) {
  return request({
    url: prefix + "/feedback/handleFeedBack",
    method: "post",
    data: param
  });
}


