/*
 * @Description: 车辆用户管理系统 实名认证 cuUserApprove
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-06 10:19:24
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/operate"

// 用户认证查询列表
export function queryUserIdentifyRecordList (param) {
  return request({
    url: prefix + "/user/queryUserIdentifyRecordList",
    method: "post",
    data: param
  });
}

// 用户认证列表删除  
export function deleteUserIdentifyRecord (param) {
  return request({
    url: prefix + "/user/deleteUserIdentifyRecord",
    method: "post",
    data: param
  });
}
