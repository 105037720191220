import request from "@/utils/request";
const prefix = "/operate"

export function queryInOut (param) {
  return request({
    url: prefix + "/PassagewayController/queryListByPage",
    method: "post",
    data: param
  });
}
//插入数据一条
export function insertInOut (param) {
  return request({
    url: prefix + "/PassagewayController/insert",
    method: "post",
    data: param
  });
}
//更新一条
export function updateInOut (param) {
  return request({
    url: prefix + "/PassagewayController/update",
    method: "post",
    data: param
  });
}
//批量删除数据
export function deleteListInOut (param) {
  return request({
    url: prefix + "/PassagewayController/deleteList",
    method: "post",
    data: param
  });
}
// //删除一条
// export function deleteInOut (param) {
//   return request({
//     url: "/PassagewayController/delete",
//     method: "post",
//     data: param
//   });
// }
