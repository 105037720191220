/*
 * @Description: 人行道停车管理 运营管理 异常订单 parkAbnormalOrder
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2023-01-16 15:36:56
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/order"

// 异常标记列表查询
export function listByPageOut (param) {
  return request({
    url: prefix + "/orderOperationRecord/listByPageOut",
    method: "post",
    data: param
  });
}

// 点击查看详情
export function getMarkOrder (param) {
  return request({
    url: prefix + "/orderOperationRecord/getMarkOrder/" + param.orderSequence,
    method: "post",
    data: param
  });
}

