/*
 * @Description:路内停车管理系统 员工管理 班次管理 classesManagement
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:15:45
 * @LastEditors: GeChang
 */
import request from "@/utils/request";

const prefix = "/operate"

//获取列表
export function classManagementGetList (param) {
  return request({
    url: prefix + "/shift/queryShiftList",
    method: "post",
    data: param
  });
}

//新增数据
export function classManagementInsert (param) {
  return request({
    url: prefix + "/shift/insertShift",
    method: "post",
    data: param
  });
}

//修改数据
export function classManagementUpdate (param) {
  return request({
    url: prefix + "/shift/updateShift",
    method: "post",
    data: param
  });
}

//删除数据
export function classManagementDelete (param) {
  return request({
    url: prefix + "/shift/deleteShift",
    method: "post",
    data: param
  });
}
