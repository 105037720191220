/*
 * @Description:财务管理系统 退款管理 reFundManagement
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-08-30 13:45:51
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/order"

// 退款记录
export function queryRefundlist (param) {
  return request({
    url: prefix + "/Refund/getRefundList",
    method: "post",
    data: param
  });
}

// 退款金额
export function getRefundCharts (param) {
  return request({
    url: prefix + "/Refund/getRefundCharts",
    method: "post",
    data: param
  });
}
