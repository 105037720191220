/*
 * @Description: 路内管理 运营管理 业务订单 businessOrders.js
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-02 17:09:45
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = '/operate'
const prefix1 = "/order"

// 根据车位获取订单
export function queryOrderOnlineByParkSpaceId (param) {
  return request({
    url: prefix1 + '/OrderController/queryOrderOnlineByParkSpaceId',
    method: 'post',
    data: param
  })
}
// 支付时序图
export function getPayAndRefundRecordMap (param) {
  return request({
    url: prefix1 + "/OrderController/getPayAndRefundRecordMap",
    method: "post",
    data: param
  });
}
// 异常标记
export function abolitionOrder (param) {
  return request({
    url: prefix1 + "/OrderController/abolitionOrder",
    method: "post",
    data: param
  });
}
// 实时订单/历史记录 订单查询
export function queryOrderList (param) {
  return request({
    url: prefix + "/order/queryOrderList",
    method: "post",
    data: param
  });
}

// 业务订单修改
export function editOrder (param) {
  return request({
    url: prefix + "/order/editOrder",
    method: "post",
    data: param
  });
}


