/*
 * @Description:运维管理系统 监控管理 在线监控  onlineMonitor
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:01:48
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/operate"
// 维护工单
export function createWorkTicketByManager (param) {
  return request({
    url: prefix + "/workTicket/createWorkTicketByManager",
    method: "post",
    data: param
  });
}
