/*
 * @Description:人行道停车运营管理 运营管理 异常放行 abnormalRelease
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:26:18
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/operate"
// 获取列表
export function getListByPage (param) {
  return request({
    url: prefix + "/freeOrderRecord/getListByPage",
    method: "post",
    data: param
  });
}