/*
 * @Description:路内停车管理系统 运营管理 缴费记录 paymentRecords
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:12:51
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/operate"

export function queryOrderPayRecordIn (param) {
  return request({
    url: prefix + "/payment/queryOrderPayRecordIn",
    method: "post",
    data: param
  });
}

// 缴费记录统计
export function queryParkingFeeIn (param) {
  return request({
    url: prefix + "/payment/queryParkingFeeIn",
    method: "post",
    data: param
  });
}

