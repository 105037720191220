/*
 * @Description:运维管理 设备管理 诱导屏  inductionScreen
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-06-28 10:47:33
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/operate"

// 查询诱导屏
export function queryGuidingScreenList (param) {
  return request({
    url: prefix + "/guidingScreen/queryGuidingScreenList",
    method: "post",
    data: param
  });
}
// 新增诱导屏
export function addGuidingScreen (param) {
  return request({
    url: prefix + "/guidingScreen/addGuidingScreen",
    method: "post",
    data: param
  });
}
// 修改诱导屏
export function updateGuidingScreen (param) {
  return request({
    url: prefix + "/guidingScreen/updateGuidingScreen",
    method: "post",
    data: param
  });
}
// 删除诱导屏
export function deleteGuidingScreen (param) {
  return request({
    url: prefix + "/guidingScreen/deleteGuidingScreen",
    method: "post",
    data: param
  });
}