/*
 * @Description:路内停车管理系统 绩效管理 取证率统计 forensicsRateStatistics
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:14:15
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/analysis"
//取证总览
export function proportionOfEvidence (param) {
  return request({
    url: prefix + "/parkAnalysis/proportionOfEvidence",
    method: "post",
    data: param
  });
}
//  
export function listOrderNumWithEvidence (param) {
  return request({
    url: prefix + "/parkAnalysis/listOrderNumWithEvidence",
    method: "post",
    data: param
  });
}

// 
export function listOrderNumWithOutEvidence (param) {
  return request({
    url: prefix + "/parkAnalysis/listOrderNumWithOutEvidence",
    method: "post",
    data: param
  });
}