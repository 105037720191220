/*
 * @Description: 系统菜单管理 systemMenuManagement.js
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-03 16:41:56
 * @LastEditors: zhoucheng
 */

import request from "@/utils/request";

const prefix = '/login'

// 登录接口
export function loginIn (param) {
  return request({
    url: prefix + "/LoginController/loginIn",
    method: "post",
    data: param
  });
}

// 登出接口
export function loginOut (param) {
  return request({
    url: prefix + "/LoginController/loginOut",
    method: "post",
    data: param
  });
}

//修改密码接口
export function updatePassWord (param) {
  return request({
    url: prefix + "/LoginController/updatePassWord",
    method: "post",
    data: param
  });
}
//验证码接口
// export function loginValidateCode () {
//   return request({
//     url: prefix + "/LoginController/loginValidateCode",
//     method: "post",
//     responseType: 'blob'
//   });
// }

//验证码接口
export function captchaImage (param) {
  return request({
    url: prefix + "/LoginController/loginValidateCode",
    method: "get",
    data: param
  });
}



