/*
 * @Description:commonAxios
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-05-19 13:56:55
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/operate"
const prefix1 = "/order"
const prefix2 = "/bill"

// 计费接口
export function charge (param) {
  return request({
    url: prefix2 + '/ChargingController/charge',
    method: 'post',
    data: param
  })
}

// 车行道 业务订单改单
export function carParkEditOrder (param) {
  return request({
    url: prefix + "/order/carParkEditOrder",
    method: "post",
    data: param
  });
}

// 人行道 业务订单改单
export function sidewalkParkEditOrder (param) {
  return request({
    url: prefix + "/order/sidewalkParkEditOrder",
    method: "post",
    data: param
  });
}

// 入场补录
export function supplementOrderForCome (param) {
  return request({
    url: prefix1 + "/OrderController/supplementOrderForCome",
    method: "post",
    data: param
  });
}

// 离场补录
export function supplementOrderForLeave (param) {
  return request({
    url: prefix1 + "/OrderController/supplementOrderForLeave",
    method: "post",
    data: param
  });
}

// 0元放行
export function makeOrderFree (param) {
  return request({
    url: prefix + "/order/makeOrderFree",
    method: "post",
    data: param
  });
}

// 停车监管修改进场
export function editOrderForComeV2 (param) {
  return request({
    url: prefix + "/order/editOrderForComeV2",
    method: "post",
    data: param
  });
}

// 停车监管修改出场
export function editOrderForLeaveV2 (param) {
  return request({
    url: prefix + "/order/editOrderForLeaveV2",
    method: "post",
    data: param
  });
}