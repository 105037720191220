/*
 * @Description:人行道停车运营管理 运营管理 过车记录 carBeforeRecord
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:26:18
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/operate"
// 入场
export function passRecord (param) {
  return request({
    url: prefix + "/parkRecord/passRecord",
    method: "post",
    data: param
  });
}
// 出场
export function passRecordOut (param) {
  return request({
    url: prefix + "/parkRecord/passRecordOut",
    method: "post",
    data: param
  });
}
