/*
 * @Description: 车辆用户管理系统 运营管理 优惠活动 cuDiscountActivity
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-06 10:00:29
 * @LastEditors: LinFeng
 */
import request from "@/utils/request";

const prefix = "/operate"

// 查询
export function select (param) {
  return request({
    url: prefix + "/PreferentialActivity/select",
    method: "post",
    data: param
  });
}
// 新增
export function insert (param) {
  return request({
    url: prefix + "/PreferentialActivity/insert",
    method: "post",
    data: param
  });
}
// 修改
export function update (param) {
  return request({
    url: prefix + "/PreferentialActivity/update",
    method: "post",
    data: param
  });
}

// 删除
export function activityDelete (param) {
  return request({
    url: prefix + "/PreferentialActivity/delete",
    method: "post",
    data: param
  });
}

// 根据活动id查询规则
export function selectByActivity (param) {
  return request({
    url: prefix + "/PreferentialActivity/selectByActivity",
    method: "post",
    data: param
  });
}

// 活动新增规则
export function insertRule (param) {
  return request({
    url: prefix + "/PreferentialActivity/insertRule",
    method: "post",
    data: param
  });
}

// 删除规则
export function deleteRule (param) {
  return request({
    url: prefix + "/PreferentialActivity/deleteRule",
    method: "post",
    data: param
  });
}
