/*
 * @Description: 系统管理 系统角色管理 systemRoleManagement
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-06 10:37:12
 * @LastEditors: yanxiao
 */
import request from "@/utils/request";

const prefix = '/login'

// 系统角色管理分页查询数据
export function queryListByPage (param) {
  return request({
    url: prefix + "/RoleInfoController/queryListByPage",
    method: "post",
    data: param
  });
}

// 系统角色管理查询-无分页
export function queryList (param) {
  return request({
    url: prefix + "/RoleInfoController/queryList",
    method: "post",
    data: param
  });
}

//  系统角色管理分页修改一条数据
export function roleUpdate (param) {
  return request({
    url: prefix + "/RoleInfoController/update",
    method: "post",
    data: param
  });
}

// 系统角色管理新增一条数据
export function roleInsert (param) {
  return request({
    url: prefix + "/RoleInfoController/insert",
    method: "post",
    data: param
  });
}

// 系统角色管理删除一条数据
export function roleDelete (param) {
  return request({
    url: prefix + "/RoleInfoController/delete",
    method: "post",
    data: param
  });
}

// // 系统角色管理删除一条数据
// export function queryRoleListByPage (param) {
//   return request({
//     url: prefix + "/UserRoleController/queryListByPage",
//     method: "post",
//     data: param
//   });
// }

//系统角色管理---用户
// 用户角色关系查询-分页
export function queryRoleListByPage (param) {
  return request({
    url: prefix + "/UserRoleController/queryListByPage",
    method: "post",
    data: param
  });
}

// 用户角色关系删除
export function UserRoledelete (param) {
  return request({
    url: prefix + "/UserRoleController/delete",
    method: "post",
    data: param
  });
}


// 角色菜单管理=======================================

// 角色菜单关系查询无分页
export function queryRoleMenu (param) {
  return request({
    url: prefix + "/RoleMenuController/queryList",
    method: "post",
    data: param
  });
}

// 角色菜单插入批量
export function insertListRoleMenu (param) {
  return request({
    url: prefix + "/RoleMenuController/insertList",
    method: "post",
    data: param
  });
}

/*角色菜单删除
参数：
[{
  角色id
}]
 */
export function delListRoleMenu (param) {
  return request({
    url: prefix + "/RoleMenuController/deleteList",
    method: "post",
    data: param
  });
}

// 角色权限管理=======================================

// 角色与权限关系表查询数据:无分页
export function queryRoleAuthority (param) {
  return request({
    url: prefix + "/RoleAuthorityController/queryList",
    method: "post",
    data: param
  });
}

// 角色与权限关系表删除:批量
export function delRoleAuthority (param) {
  return request({
    url: prefix + "/RoleAuthorityController/deleteList",
    method: "post",
    data: param
  });
}

// 角色与权限关系表插入:批量
export function insertRoleAuthority (param) {
  return request({
    url: prefix + "/RoleAuthorityController/insertList",
    method: "post",
    data: param
  });
}

// 角色与用户管理

// 用户角色关系信息查询:无分页
export function queryUserRole (param) {
  return request({
    url: prefix + "/UserRoleController/queryList",
    method: "post",
    data: param
  });
}

// 用户角色关系删除信息
export function deleteUserRole (param) {
  return request({
    url: prefix + "/UserRoleController/delete",
    method: "post",
    data: param
  });
}

// 用户角色关系信息插入:批量插入
export function insertUserRole (param) {
  return request({
    url: prefix + "/UserRoleController/insertList",
    method: "post",
    data: param
  });
}