/*
 * @Description: 设备运维管理 设备管理 卡扣立柱 bayonetPillar
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2022-02-16 15:04:23
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/operate"
// 查询
export function queryViewScreenList (param) {
  return request({
    url: prefix + "/viewScreen/queryViewScreenList",
    method: "post",
    data: param
  });
}
// 新增
export function addViewScreen (param) {
  return request({
    url: prefix + "/viewScreen/addViewScreen",
    method: "post",
    data: param
  });
}
// 修改
export function updateViewScreen (param) {
  return request({
    url: prefix + "/viewScreen/updateViewScreen",
    method: "post",
    data: param
  });
}
// 删除
export function deleteViewScreen (param) {
  return request({
    url: prefix + "/viewScreen/deleteViewScreen",
    method: "post",
    data: param
  });
}
