/*
 * @Description:视频监控管理 视频监控 实时视频   realTimeVideoMonitor
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-05-19 14:00:32
 * @LastEditors: houjinduo
 */
import request from "@/utils/request";

const prefix = "/operate"

// 获取视频
export function getVideoUrl (param) {
  return request({
    url: prefix + "/lotTopCamera/getVideoUrl" + "/" + param.parkId + "/" + param.parkSpaceId,
    method: "get"
  });
}

// 根据停车场查询车位
export function queryListByPage (param) {
  return request({
    url: prefix + "/ParkSpaceController/queryListByPage",
    method: "post",
    data: param
  });
}
// 根据停车场查询车位不分页版
export function queryParkList (param) {
  return request({
    url: prefix + "/ParkSpaceController/queryList",
    method: "post",
    data: param
  });
}

