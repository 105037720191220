/*
 * @Description: 车辆用户管理系统 运营管理 优惠规则 cuDiscountRule
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-06 10:01:00
 * @LastEditors: LinFeng
 */
import request from "@/utils/request";

const prefix = "/operate"

// 查询
export function getList (param) {
  return request({
    url: prefix + "/PreferentialRule/getList",
    method: "post",
    data: param
  });
}

// 新增
export function add (param) {
  return request({
    url: prefix + "/PreferentialRule/add",
    method: "post",
    data: param
  });
}

// 修改
export function update (param) {
  return request({
    url: prefix + "/PreferentialRule/update",
    method: "post",
    data: param
  });
}

// 删除
export function deleteRule (param) {
  return request({
    url: prefix + "/PreferentialRule/delete",
    method: "post",
    data: param
  });
}



// 暂停活动
export function setStop (param) {
  return request({
    url: prefix + "/PreferentialRule/stop",
    method: "post",
    data: param
  });
}

// 继续活动
export function setContinue (param) {
  return request({
    url: prefix + "/PreferentialRule/continue",
    method: "post",
    data: param
  });
}