/*
 * @Description:路内停车管理系统 设备管理 POS机 posDev
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 21:58:52
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/operate"
// 查询
export function queryVideoList (param) {
  return request({
    url: prefix + "/video/queryVideoList",
    method: "post",
    data: param
  });
}
// 新增
export function addVideo (param) {
  return request({
    url: prefix + "/video/addVideo",
    method: "post",
    data: param
  });
}
// 修改
export function updateVideo (param) {
  return request({
    url: prefix + "/video/updateVideo",
    method: "post",
    data: param
  });
}
// 删除
export function deleteVideo (param) {
  return request({
    url: prefix + "/video/deleteVideo",
    method: "post",
    data: param
  });
}
