<!--
 * @Author: your name
 * @Date: 2021-03-08 09:57:55
 * @LastEditTime: 2023-03-17 11:58:20
 * @LastEditors: zhoucheng
 * @Description: In User Settings Edit
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// import axios from 'axios'
export default {
  data () {
    // 这里存放数据
    return {
      // 当前版本
      // version: '2.0.5'
    }
  },
  watch: {
    // 监听路由变化
    // $route () {
    //   this.queryVersion()
    // }
  },
  methods: {
    // 查询当前的版本
    // queryVersion () {
    //   if (window.location.href.includes('cqpark-test')) {
    //     // 内网
    //     axios.get('/version').then(res => {
    //       if (res.data !== this.version) {
    //         this.$router.go(0)
    //       }
    //     })
    //   } else {
    //     // 公网
    //     axios.get('/version').then(res => {
    //       if (res.data !== this.version) {
    //         this.$router.go(0)
    //       }
    //     })
    //   }
    // }
  }
}
</script>

<style lang="scss">
html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
}

#app {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  font-family: PingFangSC-Regular;
  font-size: 16px;
  letter-spacing: 0;
  @include font_color('appFontColor');
}

.router-link-active {
  text-decoration: none;
}
</style>
