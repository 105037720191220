/*
 * @Description:车辆用户管理系统 充值管理 cuRechargeManagement
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-06 09:49:17
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/"

export function demo (param) {
  return request({
    url: prefix + "/demo",
    method: "post",
    data: param
  });
}
