/*
 * @Description:路内停车管理系统 绩效管理 收费率统计 chargeLvCollection
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:13:54
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/analysis"

export function proportionOfArrears (param) {
  return request({
    url: prefix + "/parkAnalysis/proportionOfArrears",
    method: "post",
    data: param
  });
}

export function receivedMoneyOfEachPark (param) {
  return request({
    url: prefix + "/parkAnalysis/receivedMoneyOfEachPark",
    method: "post",
    data: param
  });
}

export function arrangeMoneyAmountRankingOfPark (param) {
  return request({
    url: prefix + "/parkAnalysis/arrangeMoneyAmountRankingOfPark",
    method: "post",
    data: param
  });
}

export function monthlyPaymentDistribution (param) {
  return request({
    url: prefix + "/parkAnalysis/monthlyPaymentDistribution",
    method: "post",
    data: param
  });
}

