/*
 * @Description:客服用户服务管理 信息管理 咨询记录管理 consultRemarkManage
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-05-19 13:56:55
 * @LastEditors: GeChang
 */
import request from "@/utils/request";

const prefix = "/operate"

//查
export function queryAdvisoryRecordList (param) {
  return request({
    url: prefix + "/advisoryRecord/queryAdvisoryRecordList",
    method: "post",
    data: param
  });
}
//增
export function addAdvisoryRecordt (param) {
  return request({
    url: prefix + "/advisoryRecord/addAdvisoryRecord",
    method: "post",
    data: param
  });
}
//删
export function deleteAdvisoryRecord (param) {
  return request({
    url: prefix + "/advisoryRecord/deleteAdvisoryRecord",
    method: "post",
    data: param
  });
}

//改
export function updateAdvisoryRecord (param) {
  return request({
    url: prefix + "/advisoryRecord/updateAdvisoryRecord",
    method: "post",
    data: param
  });
}
//处理
export function handleAdvisoryRecord (param) {
  return request({
    url: prefix + "/advisoryRecord/handleAdvisoryRecord",
    method: "post",
    data: param
  });
}