/*
 * @Description:运维管理 运维管理 运维工单管理 operateWorkList
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-08-16 11:59:18
 * @LastEditors: 郭云展
 */
import request from "@/utils/request";

const prefix = "/operate"

// 工单查询
export function queryWorkTicketList (param) {
  return request({
    url: prefix + "/workTicket/queryWorkTicketList",
    method: "post",
    data: param
  });
}

// 工单计数
export function queryWorkTicketCount (param) {
  return request({
    url: prefix + "/workTicket/queryWorkTicketCount",
    method: "post",
    data: param
  });
}

// 复核处理结果
export function checkWorkTicket (param) {
  return request({
    url: prefix + "/workTicket/checkWorkTicket",
    method: "post",
    data: param
  });
}
