/*
 * @Description:停车场运营管理 运营管理  缴费记录 parkPayRecord
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-07-02 15:22:25
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/operate"

export function queryOrderPayRecordOut (param) {
  return request({
    url: prefix + "/payment/queryOrderPayRecordOut",
    method: "post",
    data: param
  });
}

// 缴费记录统计
export function queryParkingFeeOut (param) {
  return request({
    url: prefix + "/payment/queryParkingFeeOut",
    method: "post",
    data: param
  });
}

