/*
 * @Description:路内停车管理系统 统计报表 收费报表 chargeStatement
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:16:34
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/"

export function demo (param) {
  return request({
    url: prefix + "/demo",
    method: "post",
    data: param
  });
}
