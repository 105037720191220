/** @Description: 车行道管理 运营管理 停车记录 parkingRecords
 * @Author:zhoucheng
 * @Date: 2021-03-25 15:13:00
 * @LastEditTime: 2021-03-25 17:11:35
 * @LastEditors: Please set LastEditors
 * @FilePath: \chongqing-parking\src\axios\parkingRecords\parkingRecords.js
 */
import request from "@/utils/request";

const prefix = '/operate'

//查询表格数据接口
export function parkRecord (param) {
  return request({
    url: prefix + "/parkRecord/parkRecord",
    method: "post",
    data: param
  });
}
