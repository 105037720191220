/*
 * @Description: 系统管理 运营商管理 operatorManagement
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2022-05-23 10:34:13
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = '/operate'

// 查询
export function queryCompanyList (param) {
  return request({
    url: prefix + "/company/queryCompanyList",
    method: "post",
    data: param
  });
}

// 新增
export function addCompany (param) {
  return request({
    url: prefix + "/company/addCompany",
    method: "post",
    data: param
  });
}

// 修改
export function updateCompany (param) {
  return request({
    url: prefix + "/company/updateCompany",
    method: "post",
    data: param
  });
}

// 删除
export function deleteCompany (param) {
  return request({
    url: prefix + "/company/deleteCompany",
    method: "post",
    data: param
  });
}