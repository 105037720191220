/*
 * @Description: getters汇总文件，统一出口
 * @Author: John Holl
 * @Github: https://github.com/hzylyh
 * @Date: 2021-04-03 10:03:24
 * @LastEditors: John Holl
 */

const getters = {
  sidebar: state => state.app.sidebar, // 整体应用状态参数，存放在./modules/app.js
  activeMenu: state => state.app.activeMenu  // 当前激活菜单
}

export default getters