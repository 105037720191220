/*
 * @Description:车行道停车管理系统 绩效管理 欠费补缴统计 subregionalCollection
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:14:34
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/analysis"

// 欠费补缴情况统计
export function getArrearsStatistics (param) {
  return request({
    url: prefix + "/parkAnalysis/getArrearsStatistics",
    method: "post",
    data: param
  });
}
// 停车场补缴订单数排行
export function parkArrangeOrderNumRanking (param) {
  return request({
    url: prefix + "/parkAnalysis/parkArrangeOrderNumRanking",
    method: "post",
    data: param
  });
}

// 欠费补缴订单各月分布
export function monthlyArrearsStatistics (param) {
  return request({
    url: prefix + "/parkAnalysis/monthlyArrearsStatistics",
    method: "post",
    data: param
  });
}

// 欠费补缴订单各时段趋势
export function hourlyArrearsStatistics (param) {
  return request({
    url: prefix + "/parkAnalysis/hourlyArrearsStatistics",
    method: "post",
    data: param
  });
}

