/*
 * @Description:客服用户服务管理 客服工单管理  工单信息管理 workOrderInfoManage
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-05-19 13:56:07
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/order"

// 工单查询
export function getWorkOrderList (param) {
  return request({
    url: prefix + "/WorkOrder/getWorkOrderList",
    method: "post",
    data: param
  });
}
// 工单详情
export function getInfoById (param) {
  return request({
    url: prefix + "/WorkOrder/getInfoById",
    method: "post",
    data: param
  });
}
// 驳回工单
export function rejectWorkOrder (param) {
  return request({
    url: prefix + "/WorkOrder/rejectWorkOrder",
    method: "post",
    data: param
  });
}
// 免单
export function invalidateOrder (param) {
  return request({
    url: prefix + "/WorkOrder/invalidateOrder",
    method: "post",
    data: param
  });
}
// 改单
export function dealWorkOrder (param) {
  return request({
    url: prefix + "/WorkOrder/dealWorkOrder",
    method: "post",
    data: param
  });
}