/*
 * @Description: 路内停车管理系统 员工管理 排班管理 workForceManagement
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:16:14
 * @LastEditors: libin
 */
import request from "@/utils/request";

const prefix = "/operate"
//新增排班
export function insertInspectionSchedule (param) {
  return request({
    url: prefix + "/inspectionSchedule/insertInspectionSchedule",
    method: "post",
    data: param
  });
}
//排班列表查询
export function queryInspectionSchedule (param) {
  return request({
    url: prefix + "/inspectionSchedule/queryInspectionSchedule",
    method: "post",
    data: param
  });
}
//修改排班
export function updateInspectionSchedule (param) {
  return request({
    url: prefix + "/inspectionSchedule/updateInspectionSchedule",
    method: "post",
    data: param
  });
}
//修改排班
export function deleteInspectionSchedule (param) {
  return request({
    url: prefix + "/inspectionSchedule/deleteInspectionSchedule",
    method: "post",
    data: param
  });
}
//员工下拉菜单
export function queryDictEmployee (param) {
  return request({
    url: prefix + "/inspectionSchedule/queryDictEmployee",
    method: "post",
    data: param
  });
}
