/*
 * @Description:路内停车管理系统 运营管理 取证记录 forensicsRecords
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:11:24
 * @LastEditors: haojing
 */
import request from "@/utils/request";

const prefix = "/operate"

export function queryListByPage (param) {
  return request({
    url: prefix + "/order/getEvidence",
    method: "post",
    data: param
  });
}
