/*
 * @Description:人行道 运营管理 车辆布控 parkCarDeploy
 * @Author: zhoucheng
 * @Github:
 * @Date: 2022-06-16 10:20:46
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/operate"

// 布控车辆查询
export function queryControlVehicleListNoAuth (param) {
  return request({
    url: prefix + "/ControlVehicleController/queryControlVehicleListNoAuth",
    method: "post",
    data: param
  });
}

// 新增
export function insert (param) {
  return request({
    url: prefix + "/ControlVehicleController/insert",
    method: "post",
    data: param
  });
}

// 修改
export function update (param) {
  return request({
    url: prefix + "/ControlVehicleController/update",
    method: "post",
    data: param
  });
}

// 删除
export function deleteBatch (param) {
  return request({
    url: prefix + "/ControlVehicleController/deleteBatch",
    method: "post",
    data: param
  });
}

// 启用
export function enable (param) {
  return request({
    url: prefix + "/ControlVehicleController/enable",
    method: "post",
    data: param
  });
}

// 停用
export function disable (param) {
  return request({
    url: prefix + "/ControlVehicleController/disable",
    method: "post",
    data: param
  });
}