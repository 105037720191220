/*
 * @Description:客服用户服务管理 申诉管理 订单申诉管理 orderComplainManage
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-05-19 13:55:47
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/order"

// 订单申诉列表 
export function getOrderAppealList (param) {
  return request({
    url: prefix + "/OrderAppeal/getOrderAppealList",
    method: "post",
    data: param
  });
}
// 查看订单详情
export function getInfoById (param) {
  return request({
    url: prefix + "/OrderAppeal/getInfoById",
    method: "post",
    data: param
  });
}
// 生成工单
export function acceptOrderAppeal (param) {
  return request({
    url: prefix + "/OrderAppeal/acceptOrderAppeal",
    method: "post",
    data: param
  });
}
// 驳回工单
export function rejectOrderAppeal (param) {
  return request({
    url: prefix + "/OrderAppeal/rejectOrderAppeal",
    method: "post",
    data: param
  });
}
