/*
 * @Description:路内运营管理系统 数据看板 资源全景 dataBoards
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:05:06
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const preFix = '/order'
const prefix = "/operate"

// 巡检员上报位置
export function getRealtimeLocation (param) {
  return request({
    url: preFix + '/inspection/getRealtimeLocation',
    method: 'get',
    data: param
  })
}

// mongo查询停车位
export function getParkSpaceListByParkId (param) {
  return request({
    url: prefix + '/ParkSpaceController/getParkSpaceListByParkId',
    method: 'post',
    data: param
  })
}

// 获取街道列表（带经纬度）
export function queryRegion (param) {
  return request({
    url: prefix + "/dict/queryRegion",
    method: "post",
    data: param
  });
}

// 车位在线状态
export function getOrderOnlineNoPage (param) {
  return request({
    url: "/order/inspection/getOrderOnlineNoPageV2",
    method: "post",
    data: param
  });
}
// 车位在线状态
// export function getOrderOnlineNoPage (param) {
//   return request({
//     url: "/order/inspection/getOrderOnlineNoPage",
//     method: "post",
//     data: param
//   });
// }

// 获取停车场收入
export function getIncome (param) {
  return request({
    url: "/order/inspection/getIncome",
    method: "post",
    data: param
  });
}
