/*
 * @Description: 车行道停车运营管理 运营管理 异常标记记录 abnormalMarkRecord
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2023-01-16 17:02:25
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/order"

// 异常标记列表查询
export function listByPageIn (param) {
  return request({
    url: prefix + "/orderOperationRecord/listByPageIn",
    method: "post",
    data: param
  });
}

// 点击查看详情
export function getMarkOrder (param) {
  return request({
    url: prefix + "/orderOperationRecord/getMarkOrder/" + param.orderSequence,
    method: "post",
    data: param
  });
}