/*
 * @Description:路内停车管理系统 员工管理 收费组管理 chargeGroupManagement
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:14:58
 * @LastEditors: GeChang
 * @LastEditors: yanxiao
 */
import request from "@/utils/request";

const prefix = "/operate"

//获取列表
export function queryInspectionGroupList (param) {
  return request({
    url: prefix + "/inspectionGroup/queryInspectionGroupList",
    method: "post",
    data: param
  });
}

//新增数据
export function insertInspectionGroup (param) {
  return request({
    url: prefix + "/inspectionGroup/insertInspectionGroup",
    method: "post",
    data: param
  });
}

//修改数据
export function updateInspectionGroup (param) {
  return request({
    url: prefix + "/inspectionGroup/updateInspectionGroup",
    method: "post",
    data: param
  });
}

//删除数据
export function deleteInspectionGroup (param) {
  return request({
    url: prefix + "/inspectionGroup/deleteInspectionGroup",
    method: "post",
    data: param
  });
}

//新增/修改成员
export function editGroupMember (param) {
  return request({
    url: prefix + "/inspectionGroup/editGroupMember",
    method: "post",
    data: param
  });
}