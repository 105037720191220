/*
 * @Description:大数据分析 停车运营分析 parkingOperationAnalysis
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-06-20 22:26:39
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/analysis"

// 泊位热力图
export function berthHeatMap (param) {
  return request({
    url: prefix + "/parkAnalysis/berthHeatMap",
    method: "post",
    data: param
  });
}

// 查询全区停车场信息
export function parkTotalNum (param) {
  return request({
    url: prefix + "/parkAnalysis/parkTotalNum",
    method: "post",
    data: param
  });
}

// 查询各街道停车泊位利用率/周转率
export function utilizationAndTurnoverRateOfEachStreet (param) {
  return request({
    url: prefix + "/parkAnalysis/utilizationAndTurnoverRateOfEachStreet",
    method: "post",
    data: param
  });
}

// 全区订单热力图 (订单总数) 
export function orderHeatMap (param) {
  return request({
    url: prefix + "//parkAnalysis/orderHeatMap",
    method: "post",
    data: param
  });
}

// 全区运营统计信息
export function operationStatistics (param) {
  return request({
    url: prefix + "/parkAnalysis/operationStatistics",
    method: "post",
    data: param
  });
}

// 各街道收入金额条形图
export function streetReceivedMoneyWithDefaultParam (param) {
  return request({
    url: prefix + "/parkAnalysis/streetReceivedMoneyWithDefaultParam",
    method: "post",
    data: param
  });
}

// 全区订单折线图数据
export function monthlyOrderStatistics (param) {
  return request({
    url: prefix + "/parkAnalysis/monthlyOrderStatistics",
    method: "post",
    data: param
  });
}

