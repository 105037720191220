/*
 * @Description: 系统管理 日志管理 登录日志 systemLoginLogManagement
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-06 10:35:14
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/login"

// 登录日志
export function queryLoginListByPage (param) {
  return request({
    url: prefix + "/LoginLogController/queryListByPage",
    method: "post",
    data: param
  });
}