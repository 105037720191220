/*
 * @Description: 注册接口
 * @Author: zhoucheng
 * @Github: https://github.com/hj327
 * @Date: 2021-03-25 09:40:05
 * @LastEditors: houjinduo
 * @LastEditTime: 2024-05-09 16:00:54
 */
//登录
import * as login from "./login/login.js";
// 下拉菜单查询 queryDict
import * as queryDict from "./queryDict/queryDict.js";

// 系统管理 
// 数据权限管理 
import * as dataRightsManagement from "./systemManagement/dataRightsManagement.js";
// 资讯管理 
import * as informationManagement from "./systemManagement/informationManagement.js";
// 轮播图管理 
import * as slideShowManagement from "./systemManagement/slideShowManagement.js";
// 操作日志 
import * as  systemHandleLogManagement from "./systemManagement/systemHandleLogManagement.js";
// 登录日志 
import * as systemLoginLogManagement from "./systemManagement/systemLoginLogManagement.js";
// 菜单管理
import * as systemMenuManagement from "./systemManagement/systemMenuManagement.js";
// 系统角色管理 
import * as  systemRoleManagement from "./systemManagement/systemRoleManagement.js";
// 系统用户管理 
import * as systemUserManagement from "./systemManagement/systemUserManagement.js";
// 运营商管理
import * as operatorManagement from "./systemManagement/operatorManagement.js";


// 路内停车管理系统
// 绩效管理 考勤记录
import * as attendanceRecord from "./onroadParkingManagement/attendanceRecord.js";
// 运营管理 业务订单
import * as businessOrders from "./onroadParkingManagement/businessOrders.js"
// 员工管理 收费组管理 
import * as chargeGroupManagement from "./onroadParkingManagement/chargeGroupManagement.js";
// 绩效管理 收费率统计 
import * as chargeLvCollection from "./onroadParkingManagement/chargeLvCollection.js";
// 员工管理 收费员管理 
import * as  chargeManManagement from "./onroadParkingManagement/chargeManManagement.js";
// 统计报表 收费报表 
import * as chargeStatement from "./onroadParkingManagement/chargeStatement.js";
// 运营管理 收费规则 
import * as charingRules from "./onroadParkingManagement/charingRules.js";
// 员工管理 班次管理 
import * as classesManagement from "./onroadParkingManagement/classesManagement.js";
// 数据看板 资源全景 
import * as dataBoards from "./onroadParkingManagement/dataBoards.js";
// 绩效管理 取证率统计 
import * as forensicsRateStatistics from "./onroadParkingManagement/forensicsRateStatistics.js";
// 运营管理 取证记录 
import * as  forensicsRecords from "./onroadParkingManagement/forensicsRecords.js";
// 运营管理 欠费记录 
import * as lackRecords from "./onroadParkingManagement/lackRecords.js";
// 运营管理 黑白名单 黑名单 
import * as onRoadblackList from "./onroadParkingManagement/onRoadblackList.js";
// 数据看板 路内运营总览 
import * as onroadOperationsOverview from "./onroadParkingManagement/onroadOperationsOverview.js";
// 财务管理 支付对账 
import * as onroadPattern from "./onroadParkingManagement/onroadPattern.js";

// 运营管理 黑白名单 白名单 
import * as  onRoadwhiteList from "./onroadParkingManagement/onRoadwhiteList.js";
// 统计报表 运营管理报表 
import * as operationsManagement from "./onroadParkingManagement/operationsManagement.js";
// 运营管理  停车记录  
import * as parkingRecords from "./onroadParkingManagement/parkingRecords.js";
// 运营管理 缴费记录 
import * as paymentRecords from "./onroadParkingManagement/paymentRecords.js";
// 运营管理 实时停车 
import * as realtimeParking from "./onroadParkingManagement/realtimeParking.js";
// 运营管理 黑名单告警
import * as blackListWarn from "./onroadParkingManagement/blackListWarn.js";
// 运营管理 异常订单 
import * as abnormalOrder from "./onroadParkingManagement/abnormalOrder.js";
// 运营管理 异常标记记录
import * as abnormalMarkRecord from "./onroadParkingManagement/abnormalMarkRecord.js";

// 数据看板  运营统计 
import * as runStatistics from "./onroadParkingManagement/runStatistics.js";
// 绩效管理 欠费补缴统计 
import * as subregionalCollection from "./onroadParkingManagement/subregionalCollection.js";
// 员工管理 排班管理 
import * as workForceManagement from "./onroadParkingManagement/workForceManagement.js";
// 路段管理 停车路段 
import * as onroadParkRoad from "./onroadParkingManagement/onroadParkRoad.js";
// 路段管理路段泊位 
import * as onroadRoadParkSpace from "./onroadParkingManagement/onroadRoadParkSpace.js";
//路段管理 路段泊位 泊位管理 
import * as onroadRoadParkSpaceNext from "./onroadParkingManagement/onroadRoadParkSpaceNext.js";
// 路内停车 路段管理-停车路段 
import * as onroadParkRoadNext from "./onroadParkingManagement/onroadParkRoadNext.js";

import * as chargeGroupMember from "./onroadParkingManagement/chargeGroupMember.js";

// 停车场管理系统
// 数据看板 运营统计
import * as parkRunStatistics from "./parkingLotManagement/parkRunStatistics.js";
// 数据看板 收入分析
import * as parkOperationsManagement from "./parkingLotManagement/parkOperationsManagement.js";

// 运营管理  缴费记录 
import * as parkPayRecord from "./parkingLotManagement/parkPayRecord.js";
// 停车管理 异常抬杆  
import * as abnormalPhenomena from "./parkingLotManagement/abnormalPhenomena.js";
// 车辆管理 黑名单车辆 
import * as blackListCar from "./parkingLotManagement/blackListCar.js";
// 停车管理 过车记录 
import * as carBeforeRecord from "./parkingLotManagement/carBeforeRecord.js";
// 出入口管理 
import * as inletOutletManage from "./parkingLotManagement/inletOutletManage.js";
// 车场管理 出入口信息 
import * as inwardOutwardInfo from "./parkingLotManagement/inwardOutwardInfo.js";
// 运营管理 业务订单 
import * as parkBusinessOrder from "./parkingLotManagement/parkBusinessOrder.js";
// 车场基础信息 停车场管理 
import * as parkingManage from "./parkingLotManagement/parkingManage.js";
// 停车管理 场内车辆  
import * as siteLayOut from "./parkingLotManagement/siteLayOut.js";
// 车辆管理 临时车辆 
import * as temporaryCar from "./parkingLotManagement/temporaryCar.js";
// 车场基础信息 车位管理 
import * as  truckSpaceManage from "./parkingLotManagement/truckSpaceManage.js";
// 车辆管理 会员车辆 
import * as vipCar from "./parkingLotManagement/vipCar.js";
// 车辆管理 黑白名单 
import * as whiteListCar from "./parkingLotManagement/whiteListCar.js";
// 运营管理 黑名单告警
import * as parkBlackListWarn from "./parkingLotManagement/parkBlackListWarn.js";
// 运营管理 欠费记录
import * as parkLackRecords from "./parkingLotManagement/parkLackRecords.js";
// 运营管理 停车监管
import * as parkingRegulation from "./parkingLotManagement/parkingRegulation.js";
// 运营管理 异常订单
import * as parkAbnormalOrder from "./parkingLotManagement/parkAbnormalOrder.js";
// 运营管理 异常放行
import * as abnormalRelease from "./parkingLotManagement/abnormalRelease.js";
// 运营管理 车辆布控
import * as parkCarDeploy from "./parkingLotManagement/parkCarDeploy.js";
// 运营管理 布控告警
import * as parkDeployAlarm from "./parkingLotManagement/parkDeployAlarm.js";
// 运营管理 异常标记记录
import * as parkAbnormalMarkRecord from "./parkingLotManagement/parkAbnormalMarkRecord.js";
// 运营管理 异常离场
import * as abnormalDeparture from "./parkingLotManagement/abnormalDeparture.js";

// 运营管理系统
// 设备管理 道闸设备 
import * as barrierGate from "./maintenanceManagement/barrierGate.js";
// 设备管理 摄像头 
import * as  cameraDev from "./maintenanceManagement/cameraDev.js";
// 监控管理 设备在线心跳  
import * as devOnlineHeartBeat from "./maintenanceManagement/devOnlineHeartBeat.js";
// 设备管理 ETC 
import * as etcDev from "./maintenanceManagement/etcDev.js";
// 设备管理 地锁设备 
import * as floorLock from "./maintenanceManagement/floorLock.js";
// 监控管理 在线监控  
import * as onlineMonitor from "./maintenanceManagement/onlineMonitor.js";
// 设备管理 环境监控 
import * as posDev from "./maintenanceManagement/posDev.js";
// 设备管理 卡口监控 
import * as bayonetMonitor from "./maintenanceManagement/bayonetMonitor.js";
// 设备管理 诱导屏  inductionScreen
import * as inductionScreen from "./maintenanceManagement/inductionScreen.js";
// 设备管理 巡检车 
import * as inspectVehicle from "./maintenanceManagement/inspectVehicle.js";
// 设备管理 卡口立柱 
import * as bayonetPillar from "./maintenanceManagement/bayonetPillar.js";
//  运维管理 运维工单管理 绑定设备清单 
import * as operateUnitDev from "./maintenanceManagement/operateUnitDev.js";
//  运维管理 运维工单管理 
import * as operateWorkList from "./maintenanceManagement/operateWorkList.js";
//  运维管理 运维工单管理 
import * as operateUnit from "./maintenanceManagement/operateUnit.js";

// 车辆用户管理系统
// 车辆认证 
import * as cuCarApprove from "./carUserManagement/cuCarApprove.js";
// 车辆管理 车辆信息 
import * as cuCarInfo from "./carUserManagement/cuCarInfo.js";
// 投诉管理 投诉管理 
import * as cuComplain from "./carUserManagement/cuComplain.js";
// 数据看板 数据看板 
import * as cuDataBoards from "./carUserManagement/cuDataBoards.js";
// 运营管理 优惠活动 
import * as cuDiscountActivity from "./carUserManagement/cuDiscountActivity.js";
// 运营管理 优惠规则 
import * as cuDiscountRule from "./carUserManagement/cuDiscountRule.js";
// 运营管理 优惠券领用统计 
import * as cuDiscountStatistic from "./carUserManagement/cuDiscountStatistic.js";
// 平台车辆统计 
import * as cuPlateCarStatistic from "./carUserManagement/cuPlateCarStatistic.js";
// 充值管理 
import * as cuRechargeManagement from "./carUserManagement/cuRechargeManagement.js";
// 实名认证 
import * as cuUserApprove from "./carUserManagement/cuUserApprove.js";
// 用户管理 用户信息 
import * as cuUserInfo from "./carUserManagement/cuUserInfo.js";
// 钱包管理 
import * as cuWalletManagement from "./carUserManagement/cuWalletManagement.js";
// 钱包消费统计 
import * as cuWalletStatisticManagement from "./carUserManagement/cuWalletStatisticManagement.js";

// 客服用户服务管理
// 信息管理 咨询记录管理 
import * as consultRemarkManage from "./customerServiceManagement/consultRemarkManage.js";
// 意见反馈管理  意见反馈管理 
import * as feedBackInfoManage from "./customerServiceManagement/feedBackInfoManage.js";
// 信息管理 帮助中心管理 
import * as helpCenterManage from "./customerServiceManagement/helpCenterManage.js";
// 信息管理 官网公告管理 
import * as officialWebManage from "./customerServiceManagement/officialWebManage.js";
// 客服工单管理  工单信息管理 
import * as workOrderInfoManage from "./customerServiceManagement/workOrderInfoManage.js";
// 申诉管理 订单申诉管理 
import * as orderComplainManage from "./customerServiceManagement/orderComplainManage.js";

// 视频监控管理
// 视频监控 历史视频 
import * as historyVideoMonitor from "./videoManage/historyVideoMonitor.js";
// 视频监控 实时视频 
import * as realTimeVideoMonitor from "./videoManage/realTimeVideoMonitor.js";

// 财务管理系统
// 财务管理 支付对账 
import * as accPayCheck from "./finanicalManaSystem/accPayCheck.js";

import * as onroadPatternDetails from "./finanicalManaSystem/onroadPatternDetails.js";
// 财务管理 支付订单 
import * as onroadPayOrder from "./finanicalManaSystem/onroadPayOrder.js";
// 财务管理 支付记录 
import * as accPayRecord from "./finanicalManaSystem/accPayRecord.js";
// 清分管理 清分配置 
import * as clearDisConfig from "./finanicalManaSystem/clearDisConfig.js";
// 清分管理 清分记录 
import * as clearDisRecord from "./finanicalManaSystem/clearDisRecord.js";
// 开票记录管理 开票记录 
import * as finInvoiceRecord from "./finanicalManaSystem/finInvoiceRecord.js";
// 开票记录管理 重新开票 
import * as reInvoiceRecord from "./finanicalManaSystem/reInvoiceRecord.js";
// 统计报表 运营统计分析 
import * as finOperatStatistics from "./finanicalManaSystem/finOperatStatistics.js";
// 结算管理 结算配置 
import * as finSettleConfig from "./finanicalManaSystem/finSettleConfig.js";
// 结算管理 结算记录 
import * as finSettleRecord from "./finanicalManaSystem/finSettleRecord.js";
// 统计报表 收费统计分析 
import * as incomeStatisticsAnalyze from "./finanicalManaSystem/incomeStatisticsAnalyze.js";
// 财务管理 停车收费统计 
import * as parkChargeStatistics from "./finanicalManaSystem/parkChargeStatistics.js";
// 退款管理 
import * as reFundManagement from "./finanicalManaSystem/reFundManagement.js";
// 短信信息 
import * as smsMessages from "./finanicalManaSystem/smsMessages.js";

// 大数据分析
import * as equipmentIndexAnalysis from "./hadoopManagement/equipmentIndexAnalysis.js";
// 停车运营分析
import * as parkingOperationAnalysis from "./hadoopManagement/parkingOperationAnalysis.js";

// 停车场出入口
import * as inOutManage from "./inOutManage/inOutManage.js";
//停车场
import * as yardManagementApi from "./parkingLotManagement/parkingManage.js";

// 公用axios
import * as commonAxios from "./commonAxios/commonAxios.js";

// 统计分析
import * as statisticAnalysisManagement from "./statisticAnalysisManagement/statisticAnalysisManagement.js";



const install = function (Vue) {
  if (install.installed) {
    return (install.installed = true);
  }
  Object.defineProperties(Vue.prototype, {
    $commonAxios: { get () { return commonAxios } },
    $abnormalDeparture: { get () { return abnormalDeparture } },
    $statisticAnalysisManagement: { get () { return statisticAnalysisManagement } },
    $login: {
      get () {
        return login;
      }
    }, $queryDict: {
      get () {
        return queryDict;
      }
    }, $dataRightsManagement: {
      get () {
        return dataRightsManagement;
      }
    }, $informationManagement: {
      get () {
        return informationManagement;
      }
    }, $slideShowManagement: {
      get () {
        return slideShowManagement;
      }
    }, $systemHandleLogManagement: {
      get () {
        return systemHandleLogManagement;
      }
    }, $systemLoginLogManagement: {
      get () {
        return systemLoginLogManagement;
      }
    }, $systemMenuManagement: {
      get () {
        return systemMenuManagement;
      }
    }, $systemRoleManagement: {
      get () {
        return systemRoleManagement;
      }
    },
    $systemUserManagement: {
      get () {
        return systemUserManagement;
      }
    }, $attendanceRecord: {
      get () {
        return attendanceRecord;
      }
    }, $businessOrders: {
      get () {
        return businessOrders;
      }
    }, $chargeGroupManagement: {
      get () {
        return chargeGroupManagement;
      }
    }, $chargeLvCollection: {
      get () {
        return chargeLvCollection;
      }
    }, $chargeManManagement: {
      get () {
        return chargeManManagement;
      }
    }, $chargeStatement: {
      get () {
        return chargeStatement;
      }
    }, $charingRules: {
      get () {
        return charingRules;
      }
    }, $classesManagement: {
      get () {
        return classesManagement;
      }
    }, $dataBoards: {
      get () {
        return dataBoards;
      }
    }, $forensicsRateStatistics: {
      get () {
        return forensicsRateStatistics;
      }
    }, $forensicsRecords: {
      get () {
        return forensicsRecords;
      }
    }, $lackRecords: {
      get () {
        return lackRecords;
      }
    }, $onRoadblackList: {
      get () {
        return onRoadblackList;
      }
    }, $onroadOperationsOverview: {
      get () {
        return onroadOperationsOverview;
      }
    }, $onroadPattern: {
      get () {
        return onroadPattern;
      }
    }, $onroadPayOrder: {
      get () {
        return onroadPayOrder;
      }
    }, $onRoadwhiteList: {
      get () {
        return onRoadwhiteList;
      }
    }, $operationsManagement: {
      get () {
        return operationsManagement;
      }
    }, $parkingRecords: {
      get () {
        return parkingRecords;
      }
    }, $paymentRecords: {
      get () {
        return paymentRecords;
      }
    }, $realtimeParking: {
      get () {
        return realtimeParking;
      }
    }, $runStatistics: {
      get () {
        return runStatistics;
      }
    }, $subregionalCollection: {
      get () {
        return subregionalCollection;
      }
    }, $workForceManagement: {
      get () {
        return workForceManagement;
      }
    }, $abnormalPhenomena: {
      get () {
        return abnormalPhenomena;
      }
    }, $blackListCar: {
      get () {
        return blackListCar;
      }
    }, $carBeforeRecord: {
      get () {
        return carBeforeRecord;
      }
    }, $inletOutletManage: {
      get () {
        return inletOutletManage;
      }
    }, $inwardOutwardInfo: {
      get () {
        return inwardOutwardInfo;
      }
    }, $parkBusinessOrder: {
      get () {
        return parkBusinessOrder;
      }
    }, $parkingManage: {
      get () {
        return parkingManage;
      }
    }, $siteLayOut: {
      get () {
        return siteLayOut;
      }
    }, $temporaryCar: {
      get () {
        return temporaryCar;
      }
    }, $truckSpaceManage: {
      get () {
        return truckSpaceManage;
      }
    }, $vipCar: {
      get () {
        return vipCar;
      }
    }, $whiteListCar: {
      get () {
        return whiteListCar;
      }
    }, $barrierGate: {
      get () {
        return barrierGate;
      }
    }, $cameraDev: {
      get () {
        return cameraDev;
      }
    }, $devOnlineHeartBeat: {
      get () {
        return devOnlineHeartBeat;
      }
    }, $etcDev: {
      get () {
        return etcDev;
      }
    }, $floorLock: {
      get () {
        return floorLock;
      }
    }, $onlineMonitor: {
      get () {
        return onlineMonitor;
      }
    }, $posDev: {
      get () {
        return posDev;
      }
    }, $cuCarApprove: {
      get () {
        return cuCarApprove;
      }
    }, $cuCarInfo: {
      get () {
        return cuCarInfo;
      }
    }, $cuComplain: {
      get () {
        return cuComplain;
      }
    }, $cuDataBoards: {
      get () {
        return cuDataBoards;
      }
    }, $cuDiscountActivity: {
      get () {
        return cuDiscountActivity;
      }
    }, $cuDiscountRule: {
      get () {
        return cuDiscountRule;
      }
    }, $cuDiscountStatistic: {
      get () {
        return cuDiscountStatistic;
      }
    }, $cuPlateCarStatistic: {
      get () {
        return cuPlateCarStatistic;
      }
    }, $cuRechargeManagement: {
      get () {
        return cuRechargeManagement;
      }
    }, $cuUserApprove: {
      get () {
        return cuUserApprove;
      }
    }, $cuUserInfo: {
      get () {
        return cuUserInfo;
      }
    }, $cuWalletManagement: {
      get () {
        return cuWalletManagement;
      }
    },
    $cuWalletStatisticManagement: {
      get () {
        return cuWalletStatisticManagement;
      }
    },
    $inOutManage: {
      get () {
        return inOutManage;
      }
    },
    $yardManagementApi: {
      get () {
        return yardManagementApi;
      }
    },
    $onroadParkRoad: {
      get () {
        return onroadParkRoad;
      }
    },
    $onroadRoadParkSpace: {
      get () {
        return onroadRoadParkSpace;
      }
    },
    $onroadRoadParkSpaceNext: {
      get () {
        return onroadRoadParkSpaceNext;
      }
    },
    $onroadParkRoadNext: {
      get () {
        return onroadParkRoadNext;
      }
    },
    $chargeGroupMember: {
      get () {
        return chargeGroupMember;
      }
    },
    $consultRemarkManage: {
      get () {
        return consultRemarkManage;
      }
    },
    $feedBackInfoManage: {
      get () {
        return feedBackInfoManage;
      }
    }, $helpCenterManage: {
      get () {
        return helpCenterManage;
      }
    }, $officialWebManage: {
      get () {
        return officialWebManage;
      }
    }, $workOrderInfoManage: {
      get () {
        return workOrderInfoManage;
      }
    }, $orderComplainManage: {
      get () {
        return orderComplainManage;
      }
    }, $historyVideoMonitor: {
      get () {
        return historyVideoMonitor;
      }
    }, $realTimeVideoMonitor: {
      get () {
        return realTimeVideoMonitor;
      }
    }, $accPayCheck: {
      get () {
        return accPayCheck;
      }
    }, $accPayRecord: {
      get () {
        return accPayRecord;
      }
    }, $clearDisConfig: {
      get () {
        return clearDisConfig;
      }
    }, $finInvoiceRecord: {
      get () {
        return finInvoiceRecord;
      }
    }, $clearDisRecord: {
      get () {
        return clearDisRecord;
      }
    }, $finOperatStatistics: {
      get () {
        return finOperatStatistics;
      }
    }, $finSettleConfig: {
      get () {
        return finSettleConfig;
      }
    }, $finSettleRecord: {
      get () {
        return finSettleRecord;
      }
    }, $incomeStatisticsAnalyze: {
      get () {
        return incomeStatisticsAnalyze;
      }
    }, $parkChargeStatistics: {
      get () {
        return parkChargeStatistics;
      }
    }, $onroadPatternDetails: {
      get () {
        return onroadPatternDetails;
      }
    }, $inductionScreen: {
      get () {
        return inductionScreen;
      }
    }, $inspectVehicle: {
      get () {
        return inspectVehicle;
      }
    }, $parkPayRecord: {
      get () {
        return parkPayRecord;
      }
    }, $operateUnitDev: {
      get () {
        return operateUnitDev;
      }
    }, $operateWorkList: {
      get () {
        return operateWorkList;
      }
    }, $operateUnit: {
      get () {
        return operateUnit;
      }
    }, $reFundManagement: {
      get () {
        return reFundManagement;
      }
    }, $smsMessages: {
      get () {
        return smsMessages;
      }
    },$blackListWarn: {
      get () {
        return blackListWarn;
      }
    }, $parkBlackListWarn: {
      get () {
        return parkBlackListWarn;
      }
    }, $bayonetMonitor: {
      get () {
        return bayonetMonitor;
      }
    }, $parkLackRecords: {
      get () {
        return parkLackRecords;
      }
    },
    $parkingRegulation: {
      get () {
        return parkingRegulation;
      }
    },
    $bayonetPillar: {
      get () {
        return bayonetPillar;
      }
    },
    $abnormalOrder: {
      get () {
        return abnormalOrder;
      }
    },
    $abnormalMarkRecord: {
      get () {
        return abnormalMarkRecord;
      }
    },
    $parkAbnormalOrder: {
      get () {
        return parkAbnormalOrder;
      }
    },
    $operatorManagement: {
      get () {
        return operatorManagement;
      }
    },
    $reInvoiceRecord: {
      get () {
        return reInvoiceRecord;
      }
    },
    $parkRunStatistics: {
      get () {
        return parkRunStatistics;
      }
    },
    $parkOperationsManagement: {
      get () {
        return parkOperationsManagement;
      }
    },
    $abnormalRelease: {
      get () {
        return abnormalRelease;
      }
    },
    $equipmentIndexAnalysis: {
      get () {
        return equipmentIndexAnalysis;
      }
    },
    $parkDeployAlarm: {
      get () {
        return parkDeployAlarm;
      }
    },
    $parkAbnormalMarkRecord: {
      get () {
        return parkAbnormalMarkRecord;
      }
    },
    $parkCarDeploy: {
      get () {
        return parkCarDeploy;
      }
    }, $parkingOperationAnalysis: {
      get () {
        return parkingOperationAnalysis;
      }
    },

  });
};

export default {
  install
};