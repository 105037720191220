/*
 * @Description: 系统管理 日志管理 操作日志 systemHandleLogManagement
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-06 10:34:40
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = '/login'

//操作日志查询
export function queryListByPage (param) {
  return request({
    url: prefix + "/OperateLogController/queryListByPage",
    method: "post",
    data: param
  });
}