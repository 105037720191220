/*
 * @Description:路内停车管理系统 统计报表 运营管理报表 operationsManagement
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:16:58
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/analysis"

// 收入统计信息查询 
export function revenueAnalysis (param) {
  return request({
    url: prefix + "/parkAnalysis/revenueAnalysis",
    method: "post",
    data: param
  });
}

// 各街道收入金额条形图
export function streetReceivedMoney (param) {
  return request({
    url: prefix + "/parkAnalysis/streetReceivedMoney",
    method: "post",
    data: param
  });
}
// 收入第一的街道的各路段收入金额条形图
export function roadReceivedMoney (param) {
  return request({
    url: prefix + "/parkAnalysis/roadReceivedMoney",
    method: "post",
    data: param
  });
}

// 收入第一的街道的收入第一的路段的各停车场收入金额条形图
export function parkReceivedMoney (param) {
  return request({
    url: prefix + "/parkAnalysis/parkReceivedMoney",
    method: "post",
    data: param
  });
}

// 渠道分布
export function paymentDistribution (param) {
  return request({
    url: prefix + "/parkAnalysis/paymentDistribution",
    method: "post",
    data: param
  });
}

// 街道停车收入TOP5时段分析
export function streetReceivedMoneyLineChart (param) {
  return request({
    url: prefix + "/parkAnalysis/streetReceivedMoneyLineChart",
    method: "post",
    data: param
  });
}