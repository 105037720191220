/*
 * @Description:客服用户服务管理 信息管理 官网公告管理 officialWebManage
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-05-19 13:57:34
 * @LastEditors: GeChang
 */
import request from "@/utils/request";

const prefix = "/operate"

// 查询公告
export function queryAnnounceList (param) {
  return request({
    url: prefix + "/announce/queryAnnounceList",
    method: "post",
    data: param
  });
}

// 新增公告
export function addAnnounce (param) {
  return request({
    url: prefix + "/announce/addAnnounce",
    method: "post",
    data: param
  });
}

// 修改公告
export function updateAnnounce (param) {
  return request({
    url: prefix + "/announce/updateAnnounce",
    method: "post",
    data: param
  });
}

// 删除公告
export function deleteAnnounce (param) {
  return request({
    url: prefix + "/announce/deleteAnnounce",
    method: "post",
    data: param
  });
}

// 发布    ------->    2为已发布
export function publish (param) {
  return request({
    url: prefix + "/announce/publish",
    method: "post",
    data: param
  });
}


// 停止发布    ------->    1为未发布
export function stopPublish (param) {
  return request({
    url: prefix + "/announce/stopPublish",
    method: "post",
    data: param
  });
}