/*
 * @Description: 车辆用户管理系统管理系统 运营管理 优惠券领用统计 cuDiscountStatistic
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-06 10:01:42
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/operate"

export function selectCuDiscountStatistic (param) {
  return request({
    url: prefix + "/ReceiveCountController/select",
    method: "post",
    data: param
  });
}
