/*
 * @Description:运维管理 运维管理 运维工单管理 operateUnit
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-08-16 12:00:13
 * @LastEditors: 郭云展
 */
import request from "@/utils/request";

const prefix = "/operate"

//查询运维单位
export function queryMaintenanceList (param) {
  return request({
    url: prefix + "/maintenance/queryMaintenanceList",
    method: "post",
    data: param
  });
}
//查询运维单信息
export function queryMaintenance(param) {
  return request({
    url: prefix + "/maintenance/queryMaintenance",
    method: "post",
    data: param
  });
}
// 新增运维单位
export function addMaintenance (param) {
  return request({
    url: prefix + "/maintenance/addMaintenance",
    method: "post",
    data: param
  });
}
// 修改运维单位
export function updateMaintenance (param) {
  return request({
    url: prefix + "/maintenance/updateMaintenance",
    method: "post",
    data: param
  });
}

// 删除运维单位
export function delMaintenance (param) {
  return request({
    url: prefix + "/maintenance/delMaintenance",
    method: "post",
    data: param
  });
}

//查询处理记录
export function queryWorkTicketDealt(param) {
  return request({
    url: prefix + "/workTicket/queryWorkTicketDealt",
    method: "post",
    data: param
  });
}
//查询复核记录
export function queryWorkTicketCheck(param) {
  return request({
    url: prefix + "/workTicket/queryWorkTicketCheck",
    method: "post",
    data: param
  });
}

//查询设备记录
export function queryDeviceInfo(param) {
  return request({
    url: prefix + "/workTicket/queryDeviceInfo",
    method: "post",
    data: param
  });
}
