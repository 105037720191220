/*
 * @Description:路内停车管理系统 数据看板 路内运营总览 onroadOperationsOverview
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:05:32
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/"

export function demo (param) {
  return request({
    url: prefix + "/demo",
    method: "post",
    data: param
  });
}
