/*
 * @Description: 人行道停车管理 运营管理 异常订单 parkAbnormalOrder
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2022-03-16 10:50:24
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/operate"
const prefix1 = "/order"

// 修改异常订单
export function updateExceptOrder (param) {
  return request({
    url: prefix + "/order/updateExceptOrder",
    method: "post",
    data: param
  });
}
// 恢复
export function recoverOrder (param) {
  return request({
    url: prefix1 + "/OrderController/recoverOrder",
    method: "post",
    data: param
  });
}


// 异常订单查询
export function queryExceptOrder (param) {
  return request({
    url: prefix + "/order/queryExceptOrderOut",
    method: "post",
    data: param
  });
}

// 删除订单
export function deleteExceptOrder (param) {
  return request({
    url: prefix + "/order/deleteExceptOrder",
    method: "post",
    data: param
  });
}

// 改单
export function dealtExceptOrder (param) {
  return request({
    url: prefix + "/order/dealtExceptOrder",
    method: "post",
    data: param
  });
}