/*
 * @Description:  路内停车管理系统-路段管理-停车路段 onroadParkRoadNext
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-04-20 11:35:56
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/operate"
const pre = "/order"

// 获取在线订单
export function getOrderOnlineNoPage (param) {
  return request({
    url: pre + "/inspection/getOrderOnlineNoPageV2",
    method: "post",
    data: param
  });
}
// 获取在线订单
// export function getOrderOnlineNoPage (param) {
//   return request({
//     url: pre + "/inspection/getOrderOnlineNoPage",
//     method: "post",
//     data: param
//   });
// }

// 获取全部停车位
export function queryListByPage (param) {
  return request({
    url: prefix + "/ParkSpaceController/queryListByPage",
    method: "post",
    data: param
  });
}
// 基础信息
export function baseInfo (param) {
  return request({
    url: prefix + "/ParkController/baseInfo",
    method: "post",
    data: param
  });
}

//线上车位状态
export function parkSpaceOnlineStatus (param) {
  return request({
    url: prefix + "/ParkController/parkSpaceOnlineStatus",
    method: "post",
    data: param
  });
}

//管理员获取线上车位状态
export function getOrderOnline (param) {
  return request({
    url: pre + "/inspection/getOrderOnline",
    method: "post",
    data: param
  });
}