/*
 * @Description: 路由跳转
 * @Author: zhuangfeiyu
 * @Date: 2021-03-07 17:05:07
 * @LastEditTime: 2024-09-25 10:00:28
 * @LastEditors: xiaosheng 11511201+fxiaosheng@user.noreply.gitee.com
 */
import VueRouter from "vue-router";
const layout = () => import("../views/layout");
export const constantRoutes = [
  {
    path: "/layout",
    component: layout,
  },
  {
    path: "",
    component: layout,
    redirect: "/login",
    meta: { title: "登录" },
  },
  {
    path: "/login",
    meta: { title: "登录" },
    component: () => import("@/views/login/index"),
  },
  {
    path: "/homeSlide",
    meta: { title: "登录过的首页" },
    component: () => import("@/views/homeSlide/index"),
  },
  // 系统管理
  {
    path: "/systemUserManagement",
    component: layout,
    meta: { title: "系统用户管理" },
    children: [
      {
        path: "/systemUserManagement",
        component: () =>
          import("@/views/systemManagement/systemUserManagement/index"),
        meta: { title: "系统用户管理" },
      },
    ],
  },
  {
    path: "/systemRoleManagement",
    component: layout,
    meta: { title: "系统角色管理" },
    children: [
      {
        path: "/systemRoleManagement",
        component: () =>
          import("@/views/systemManagement/systemRoleManagement/index"),
        meta: { title: "系统角色管理" },
      },
    ],
  },
  {
    path: "/systemMenuManagement",
    component: layout,
    meta: { title: "系统菜单管理" },
    children: [
      {
        path: "/systemMenuManagement",
        component: () =>
          import("@/views/systemManagement/systemMenuManagement/index"),
        meta: { title: "系统菜单管理" },
      },
    ],
  },
  {
    path: "/InspectorAndParkingLot",
    component: layout,
    meta: { title: "巡检员绑定车位管理" },
    children: [
      {
        path: "/InspectorAndParkingLot",
        component: () =>
          import("@/views/systemManagement/InspectorAndParkingLot/index"),
        meta: { title: "巡检员绑定车位管理" },
      },
    ],
  },
  {
    path: "/dataRightsManagement",
    component: layout,
    meta: { title: "数据权限管理" },
    children: [
      {
        path: "/dataRightsManagement",
        component: () =>
          import("@/views/systemManagement/dataRightsManagement/index"),
        meta: { title: "数据权限管理" },
      },
    ],
  },
  {
    path: "/systemLogManagement",
    component: layout,
    meta: { title: "日志管理" },
    children: [
      {
        path: "/systemHandleLogManagement",
        component: () =>
          import(
            "@/views/systemManagement/systemLogManagement/systemHandleLogManagement/index"
          ),
        meta: { title: "操作日志" },
      },
      {
        path: "/systemLoginLogManagement",
        component: () =>
          import(
            "@/views/systemManagement/systemLogManagement/systemLoginLogManagement/index"
          ),
        meta: { title: "登录日志" },
      },
    ],
  },
  {
    path: "/operatorManagement",
    component: layout,
    meta: { title: "运营商管理" },
    children: [
      {
        path: "/operatorManagement",
        component: () =>
          import("@/views/systemManagement/operatorManagement/index"),
        meta: { title: "运营商管理" },
      },
    ],
  },
  {
    path: "/AppManagement",
    component: layout,
    meta: { title: "APP管理" },
    children: [
      {
        path: "/slideShowManagement",
        component: () =>
          import(
            "@/views/systemManagement/AppManagement/slideShowManagement/index"
          ),
        meta: { title: "轮播图管理" },
      },
      {
        path: "/informationManagement",
        component: () =>
          import(
            "@/views/systemManagement/AppManagement/informationManagement/index"
          ),
        meta: { title: "资讯管理" },
      },
    ],
  },
  // 车行道停车运营管理
  {
    path: "/dataBoards",
    component: layout,
    meta: { title: "数据看板" },
    children: [
      {
        path: "/dataBoards",
        component: () =>
          import("@/views/onroadParkingManagement/dataBoards/dataBoards/index"),
        meta: { title: "数据看板" },
      },
      {
        path: "/runStatistics",
        component: () =>
          import(
            "@/views/onroadParkingManagement/dataBoards/runStatistics/index"
          ),
        meta: { title: "运营统计" },
      },
      {
        path: "/operationsManagement",
        component: () =>
          import(
            "@/views/onroadParkingManagement/dataBoards/operationsManagement/index"
          ),
        meta: { title: "运营管理报表" },
      },
      {
        path: "/onroadOperationsOverview",
        component: () =>
          import(
            "@/views/onroadParkingManagement/dataBoards/onroadOperationsOverview/index"
          ),
        meta: { title: "路内运营总览" },
      },
    ],
  },
  {
    path: "/staffManagement",
    component: layout,
    meta: { title: "员工管理" },
    children: [
      {
        path: "/classesManagement",
        component: () =>
          import(
            "@/views/onroadParkingManagement/staffManagement/classesManagement/index"
          ),
        meta: { title: " 班次管理" },
      },
      {
        path: "/chargeGroupManagement",
        component: () =>
          import(
            "@/views/onroadParkingManagement/staffManagement/chargeGroupManagement/index"
          ),
        meta: { title: "巡检组管理" },
      },
      {
        path: "/chargeManManagement",
        component: () =>
          import(
            "@/views/onroadParkingManagement/staffManagement/chargeManManagement/index"
          ),
        meta: { title: "巡检员管理" },
      },
      {
        path: "/workForceManagement",
        component: () =>
          import(
            "@/views/onroadParkingManagement/staffManagement/workForceManagement/index"
          ),
        meta: { title: "排班管理" },
      },
      {
        path: "/chargeGroupMember",
        component: () =>
          import(
            "@/views/onroadParkingManagement/staffManagement/chargeGroupManagement/chargeGroupMember/index"
          ),
        meta: { title: "成员管理" },
      },
    ],
  },
  {
    path: "/onroadRoadManagement",
    component: layout,
    meta: { title: "路段管理" },
    children: [
      {
        path: "/onroadParkRoad",
        name: "onroadParkRoad",
        component: () =>
          import(
            "@/views/onroadParkingManagement/onroadRoadManagement/onroadParkRoad/index"
          ),
        meta: { title: " 停车路段" },
      },
      {
        path: "/onroadParkRoadNext",
        component: () =>
          import(
            "@/views/onroadParkingManagement/onroadRoadManagement/onroadParkRoad/onroadParkRoadNext/index"
          ),
        meta: { title: " 泊位查询" },
      },
      {
        path: "/onroadRoadParkSpace",
        component: () =>
          import(
            "@/views/onroadParkingManagement/onroadRoadManagement/onroadRoadParkSpace/index"
          ),
        meta: { title: "路段泊位" },
      },
      {
        path: "/onroadRoadParkSpaceArea",
        component: () =>
          import(
            "@/views/onroadParkingManagement/onroadRoadManagement/onroadRoadParkSpace/onroadRoadParkSpaceArea/index"
          ),
        meta: { title: "区域管理" },
      },
      {
        path: "/onroadRoadParkSpaceNext",
        component: () =>
          import(
            "@/views/onroadParkingManagement/onroadRoadManagement/onroadRoadParkSpace/onroadRoadParkSpaceNext/index"
          ),
        meta: { title: " 泊位管理" },
      },
    ],
  },
  {
    path: "/parkingOperationManagement",
    component: layout,
    meta: { title: "运营管理" },
    children: [
      {
        path: "/businessOrders",
        component: () =>
          import(
            "@/views/onroadParkingManagement/parkingOperationManagement/businessOrders/index"
          ),
        meta: { title: "业务订单" },
      },
      {
        path: "/realtimeParking",
        component: () =>
          import(
            "@/views/onroadParkingManagement/parkingOperationManagement/realtimeParking/index"
          ),
        meta: { title: "实时停车" },
      },
      {
        path: "/parkingRecords",
        component: () =>
          import(
            "@/views/onroadParkingManagement/parkingOperationManagement/parkingRecords/index"
          ),
        meta: { title: "停车记录" },
      },
      {
        path: "/lackRecords",
        component: () =>
          import(
            "@/views/onroadParkingManagement/parkingOperationManagement/lackRecords/index"
          ),
        meta: { title: "欠费记录" },
      },
      {
        path: "/paymentRecords",
        component: () =>
          import(
            "@/views/onroadParkingManagement/parkingOperationManagement/paymentRecords/index"
          ),
        meta: { title: "缴费记录" },
      },
      {
        path: "/paymentRecordsInfo",
        name: "paymentRecordsInfo",
        component: () =>
          import(
            "@/views/onroadParkingManagement/parkingOperationManagement/paymentRecords/paymentRecordsInfo/index"
          ),
        meta: { title: "缴费记录详情" },
      },
      {
        path: "/chargingRules",
        component: () =>
          import(
            "@/views/onroadParkingManagement/parkingOperationManagement/chargingRules/index"
          ),
        meta: { title: "收费规则" },
      },
      {
        path: "/blackList",
        component: () =>
          import(
            "@/views/onroadParkingManagement/parkingOperationManagement/blackWhiteList/blackList.vue"
          ),
        meta: { title: "黑名单车辆" },
      },
      {
        path: "/whiteList",
        component: () =>
          import(
            "@/views/onroadParkingManagement/parkingOperationManagement/blackWhiteList/whiteList.vue"
          ),
        meta: { title: "白名单车辆" },
      },
      {
        path: "/blackListWarn",
        component: () =>
          import(
            "@/views/onroadParkingManagement/parkingOperationManagement/blackListWarn/index.vue"
          ),
        meta: { title: "黑名单告警" },
      },
      {
        path: "/forensicsRecords",
        component: () =>
          import(
            "@/views/onroadParkingManagement/parkingOperationManagement/forensicsRecords/index"
          ),
        meta: { title: "取证记录" },
      },
      {
        path: "/abnormalOrder",
        component: () =>
          import(
            "@/views/onroadParkingManagement/parkingOperationManagement/abnormalOrder/index"
          ),
        meta: { title: "异常订单" },
      },
      {
        path: "/abnormalMarkRecord",
        component: () =>
          import(
            "@/views/onroadParkingManagement/parkingOperationManagement/abnormalMarkRecord/index"
          ),
        meta: { title: "异常标记记录" },
      },
      {
        path: "/carDeploy",
        component: () =>
          import(
            "@/views/onroadParkingManagement/parkingOperationManagement/carDeploy/index"
          ),
        meta: { title: "车辆布控" },
      },
      {
        path: "/deployAlarm",
        component: () =>
          import(
            "@/views/onroadParkingManagement/parkingOperationManagement/deployAlarm/index"
          ),
        meta: { title: "布控告警" },
      },
    ],
  },
  {
    path: "/performanceManagement",
    component: layout,
    meta: { title: "绩效管理" },
    children: [
      {
        path: "/attendanceRecord",
        component: () =>
          import(
            "@/views/onroadParkingManagement/performanceManagement/attendanceRecord/index"
          ),
        meta: { title: "考勤记录" },
      },
      {
        path: "/forensicsRateStatistics",
        component: () =>
          import(
            "@/views/onroadParkingManagement/performanceManagement/forensicsRateStatistics/index"
          ),
        meta: { title: "取证率统计" },
      },
      {
        path: "/chargeLvCollection",
        component: () =>
          import(
            "@/views/onroadParkingManagement/performanceManagement/chargeLvCollection/index"
          ),
        meta: { title: "收费率统计" },
      },
      {
        path: "/subregionalCollection",
        component: () =>
          import(
            "@/views/onroadParkingManagement/performanceManagement/subregionalCollection/index"
          ),
        meta: { title: "欠费补缴统计" },
      },
    ],
  },
  // 人行道停车运营管理
  {
    path: "/parkDataBorads",
    component: layout,
    meta: { title: "数据看板" },
    children: [
      {
        path: "/parkDataBorads",
        component: () =>
          import("@/views/parkingLotManagement/parkDataBorads/index"),
        meta: { title: "资源全景" },
      },
      {
        path: "/parkRunStatistics",
        component: () =>
          import(
            "@/views/parkingLotManagement/parkDataBorads/parkRunStatistics/index"
          ),
        meta: { title: "运营统计" },
      },
      {
        path: "/parkOperationsManagement",
        component: () =>
          import(
            "@/views/parkingLotManagement/parkDataBorads/parkOperationsManagement/index"
          ),
        meta: { title: "收入分析" },
      },
    ],
  },
  {
    path: "/yardManagement",
    component: layout,
    meta: { title: "停车场管理" },
    children: [
      {
        path: "/parkingManage",
        component: () =>
          import(
            "@/views/parkingLotManagement/yardManagement/yardBasicInfo/parkingManage.vue"
          ),
        meta: { title: "停车场管理" },
      },
      {
        path: "/truckSpaceManage",
        component: () =>
          import(
            "@/views/parkingLotManagement/yardManagement/yardBasicInfo/truckSpaceManage.vue"
          ),
        meta: { title: "车位管理" },
      },
      {
        path: "/inletOutletManage",
        component: () =>
          import(
            "@/views/parkingLotManagement/yardManagement/yardBasicInfo/inletOutletManage.vue"
          ),
        meta: { title: "出入口管理" },
      },
      {
        path: "/inwardOutwardInfo",
        component: () =>
          import(
            "@/views/parkingLotManagement/yardManagement/inwardOutwardInfo/index"
          ),
        meta: { title: "出入口信息" },
      },
      {
        path: "/parkingSpaceManagement",
        name: "parkingSpaceManagement",
        component: () =>
          import(
            "@/views/parkingLotManagement/yardManagement/yardBasicInfo/part/parkingSpaceManagement"
          ),
        meta: { title: "车位详情" },
      },
    ],
  },
  {
    path: "/carManagement",
    component: layout,
    meta: { title: "车辆管理" },
    children: [
      {
        path: "/whiteListCar",
        component: () =>
          import("@/views/parkingLotManagement/carManagement/whiteListCar.vue"),
        meta: { title: "白名单车辆" },
      },
      {
        path: "/blackListCar",
        component: () =>
          import("@/views/parkingLotManagement/carManagement/blackListCar.vue"),
        meta: { title: "黑名单车辆" },
      },
      {
        path: "/vipCar",
        component: () =>
          import("@/views/parkingLotManagement/carManagement/vipCar.vue"),
        meta: { title: "会员车辆" },
      },
      {
        path: "/temporaryCar",
        component: () =>
          import("@/views/parkingLotManagement/carManagement/temporaryCar.vue"),
        meta: { title: "临时车辆" },
      },
    ],
  },
  {
    path: "/parkingManagement",
    component: layout,
    meta: { title: "运营管理" },
    children: [
      {
        path: "/carBeforeRecord",
        component: () =>
          import(
            "@/views/parkingLotManagement/parkingManagement/carBeforeRecord/index"
          ),
        meta: { title: "过车记录" },
      },
      {
        path: "/siteLayOut",
        component: () =>
          import(
            "@/views/parkingLotManagement/parkingManagement/siteLayOut/index"
          ),
        meta: { title: "场内车辆" },
      },
      {
        path: "/parkBusinessOrder",
        component: () =>
          import(
            "@/views/parkingLotManagement/parkingManagement/parkBusinessOrder/index"
          ),
        meta: { title: "业务订单" },
      },
      {
        path: "/parkPayRecord",
        component: () =>
          import(
            "@/views/parkingLotManagement/parkingManagement/parkPayRecord/index"
          ),
        meta: { title: "缴费记录" },
      },
      {
        path: "/parkPayRecordInfo",
        component: () =>
          import(
            "@/views/parkingLotManagement/parkingManagement/parkPayRecord/parkPayRecordInfo/index"
          ),
        meta: { title: "缴费记录详情" },
      },
      {
        path: "/parkBlackListWarn",
        component: () =>
          import(
            "@/views/parkingLotManagement/parkingManagement/parkBlackListWarn/index"
          ),
        meta: { title: "黑名单告警" },
      },
      {
        path: "/parkLackRecords",
        component: () =>
          import(
            "@/views/parkingLotManagement/parkingManagement/parkLackRecords/index"
          ),
        meta: { title: "欠费记录" },
      },
      {
        path: "/abnormalPhenomena",
        component: () =>
          import(
            "@/views/parkingLotManagement/parkingManagement/abnormalPhenomena/index"
          ),
        meta: { title: "异常抬杆" },
      },
      {
        path: "/parkingRegulation",
        component: () =>
          import(
            "@/views/parkingLotManagement/parkingManagement/parkingRegulation/index"
          ),
        meta: { title: "停车监管" },
      },
      {
        path: "/parkAbnormalOrder",
        component: () =>
          import(
            "@/views/parkingLotManagement/parkingManagement/parkAbnormalOrder/index"
          ),
        meta: { title: "异常订单" },
      },
      {
        path: "/parkAbnormalMarkRecord",
        component: () =>
          import(
            "@/views/parkingLotManagement/parkingManagement/parkAbnormalMarkRecord/index"
          ),
        meta: { title: "异常标记记录" },
      },
      {
        path: "/abnormalRelease",
        component: () =>
          import(
            "@/views/parkingLotManagement/parkingManagement/abnormalRelease/index"
          ),
        meta: { title: "异常放行" },
      },
      {
        path: "/parkCarDeploy",
        component: () =>
          import(
            "@/views/parkingLotManagement/parkingManagement/parkCarDeploy/index"
          ),
        meta: { title: "车辆布控" },
      },
      {
        path: "/parkDeployAlarm",
        component: () =>
          import(
            "@/views/parkingLotManagement/parkingManagement/parkDeployAlarm/index"
          ),
        meta: { title: "车辆布控告警" },
      },
      {
        path: "/abnormalDeparture",
        component: () =>
          import(
            "@/views/parkingLotManagement/parkingManagement/abnormalDeparture/index"
          ),
        meta: { title: "异常离场" },
      },
    ],
  },
  //运维管理系统-设备管理
  {
    path: "/deviceManagement",
    component: layout,
    meta: { title: "设备管理" },
    children: [
      {
        path: "/floorLock",
        component: () =>
          import(
            "@/views/maintenanceManagement/deviceManagement/floorLock/index"
          ),
        meta: { title: "地锁设备" },
      },
      {
        path: "/barrierGate",
        component: () =>
          import(
            "@/views/maintenanceManagement/deviceManagement/barrierGate/index"
          ),
        meta: { title: "道闸设备" },
      },
      {
        path: "/posDev",
        component: () =>
          import("@/views/maintenanceManagement/deviceManagement/posDev/index"),
        meta: { title: "环境监控" },
      },
      {
        path: "/bayonetMonitor",
        component: () =>
          import(
            "@/views/maintenanceManagement/deviceManagement/bayonetMonitor/index"
          ),
        meta: { title: "卡口监控" },
      },
      {
        path: "/cameraDev",
        component: () =>
          import(
            "@/views/maintenanceManagement/deviceManagement/cameraDev/index"
          ),
        meta: { title: "摄像头" },
      },
      {
        path: "/etcDev",
        component: () =>
          import("@/views/maintenanceManagement/deviceManagement/etcDev/index"),
        meta: { title: "ETC" },
      },
      {
        path: "/inductionScreen",
        component: () =>
          import(
            "@/views/maintenanceManagement/deviceManagement/inductionScreen/index"
          ),
        meta: { title: "诱导屏" },
      },
      {
        path: "/inspectVehicle",
        component: () =>
          import(
            "@/views/maintenanceManagement/deviceManagement/inspectVehicle/index"
          ),
        meta: { title: "巡检车" },
      },
      {
        path: "/bayonetPillar",
        component: () =>
          import(
            "@/views/maintenanceManagement/deviceManagement/bayonetPillar/index"
          ),
        meta: { title: "卡口立柱" },
      },
    ],
  },
  // 运维管理系统-监控管理
  {
    path: "/monitorManagement",
    component: layout,
    meta: { title: "监控管理" },
    children: [
      {
        path: "/devOnlineHeartBeat",
        component: () =>
          import(
            "@/views/maintenanceManagement/monitorManagement/devOnlineHeartBeat/index"
          ),
        meta: { title: "设备在线心跳" },
      },
      {
        path: "/onlineMonitor",
        component: () =>
          import(
            "@/views/maintenanceManagement/monitorManagement/onlineMonitor/index"
          ),
        meta: { title: "在线监控" },
      },
    ],
  },
  {
    path: "/operateManagement",
    component: layout,
    meta: { title: "运维管理" },
    children: [
      {
        path: "/operateWorkList",
        component: () =>
          import(
            "@/views/maintenanceManagement/operateManagement/operateWorkList/index"
          ),
        meta: { title: "运维工单管理" },
      },
      {
        path: "/operateUnit",
        component: () =>
          import(
            "@/views/maintenanceManagement/operateManagement/operateUnit/index"
          ),
        meta: { title: "运维单位管理" },
      },
      {
        path: "/operateUnitDev",
        name: "operateUnitDev",
        component: () =>
          import(
            "@/views/maintenanceManagement/operateManagement/operateUnit/operateUnitDev/index"
          ),
        meta: { title: "运维单位管理-绑定设备清单" },
      },
    ],
  },
  // 车辆用户管理系统
  {
    path: "/cuHomepageStatistic",
    component: layout,
    meta: { title: "首页统计" },
    children: [
      {
        path: "/cuDataBoards",
        component: () =>
          import(
            "@/views/carUserManagement/cuHomepageStatistic/cuDataBoards/index"
          ),
        meta: { title: "数据看板" },
      },
      {
        path: "/cuPlateUserStatistic",
        component: () =>
          import(
            "@/views/carUserManagement/cuHomepageStatistic/cuPlateUserStatistic/index"
          ),
        meta: { title: "平台用户统计" },
      },
      {
        path: "/cuPlateCarStatistic",
        component: () =>
          import(
            "@/views/carUserManagement/cuHomepageStatistic/cuPlateCarStatistic/index"
          ),
        meta: { title: "平台车辆统计" },
      },
    ],
  },
  {
    path: "/cuUserManagement",
    component: layout,
    meta: { title: "用户管理" },
    children: [
      {
        path: "/cuUserInfo",
        component: () =>
          import("@/views/carUserManagement/cuUserManagement/cuUserInfo/index"),
        meta: { title: "用户信息" },
      },
      {
        path: "/cuUserApprove",
        component: () =>
          import(
            "@/views/carUserManagement/cuUserManagement/cuUserApprove/index"
          ),
        meta: { title: "实名认证" },
      },
      {
        path: "/cuUserPic",
        component: () =>
          import("@/views/carUserManagement/cuUserManagement/cuUserPic/index"),
        meta: { title: "车主画像" },
      },
    ],
  },
  {
    path: "/cuCarManagement",
    component: layout,
    meta: { title: "车辆管理" },
    children: [
      {
        path: "/cuCarInfo",
        component: () =>
          import("@/views/carUserManagement/cuCarManagement/cuCarInfo/index"),
        meta: { title: "车辆信息" },
      },
      {
        path: "/cuCarApprove",
        component: () =>
          import(
            "@/views/carUserManagement/cuCarManagement/cuCarApprove/index"
          ),
        meta: { title: "车辆认证" },
      },
    ],
  },
  {
    path: "/cuRunManagement",
    component: layout,
    meta: { title: "运维管理" },
    children: [
      {
        path: "/cuDiscountRule",
        component: () =>
          import(
            "@/views/carUserManagement/cuRunManagement/cuDiscountRule/index"
          ),
        meta: { title: "优惠规则" },
      },
      {
        path: "/cuDiscountActivity",
        component: () =>
          import(
            "@/views/carUserManagement/cuRunManagement/cuDiscountActivity/index"
          ),
        meta: { title: "优惠活动" },
      },
      {
        path: "/cuDiscountAcRule",
        component: () =>
          import(
            "@/views/carUserManagement/cuRunManagement/cuDiscountActivity/cuDiscountAcRule/index"
          ),
        meta: { title: "优惠活动配置规则" },
      },
      {
        path: "/cuDiscountStatistic",
        component: () =>
          import(
            "@/views/carUserManagement/cuRunManagement/cuDiscountStatistic/index"
          ),
        meta: { title: "优惠券领用统计" },
      },
      {
        path: "/cuDiscountActivityDetails",
        component: () =>
          import(
            "@/views/carUserManagement/cuRunManagement/cuDiscountActivity/cuDiscountActivityDetails/index"
          ),
        meta: { title: "优惠活动预览" },
      },
    ],
  },
  {
    path: "/cuFinancialManagement",
    component: layout,
    meta: { title: "财务管理" },
    children: [
      {
        path: "/cuRechargeManagement",
        component: () =>
          import(
            "@/views/carUserManagement/cuFinancialManagement/cuRechargeManagement/index"
          ),
        meta: { title: "充值管理" },
      },
      {
        path: "/cuWalletManagement",
        component: () =>
          import(
            "@/views/carUserManagement/cuFinancialManagement/cuWalletManagement/index"
          ),
        meta: { title: "钱包管理" },
      },
      {
        path: "/cuWalletStatisticManagement",
        component: () =>
          import(
            "@/views/carUserManagement/cuFinancialManagement/cuWalletStatisticManagement/index"
          ),
        meta: { title: "钱包消费统计" },
      },
    ],
  },
  {
    path: "/cuComplaintManagement",
    component: layout,
    meta: { title: "投诉管理" },
    children: [
      {
        path: "/cuComplain",
        component: () =>
          import(
            "@/views/carUserManagement/cuComplaintManagement/cuComplain/index"
          ),
        meta: { title: "投诉管理" },
      },
    ],
  },
  // 客服用户服务管理
  {
    path: "/customerServiceAnalyze",
    component: layout,
    meta: { title: "客服服务分析" },
    children: [
      {
        path: "/customerServiceAnalyzeStatistics",
        component: () =>
          import(
            "@/views/customerServiceManagement/customerServiceAnalyze/customerServiceAnalyzeStatistics/index"
          ),
        meta: { title: "客服服务统计分析" },
      },
    ],
  },
  {
    path: "/complaintManage",
    component: layout,
    meta: { title: "申诉管理" },
    children: [
      {
        path: "/orderComplainManage",
        component: () =>
          import(
            "@/views/customerServiceManagement/complaintManage/orderComplainManage/index"
          ),
        meta: { title: "订单申诉管理" },
      },
    ],
  },
  {
    path: "/customerServiceWorkOrderManage",
    component: layout,
    meta: { title: "客服工单管理" },
    children: [
      {
        path: "/workOrderInfoManage",
        component: () =>
          import(
            "@/views/customerServiceManagement/customerServiceWorkOrderManage/workOrderInfoManage/index"
          ),
        meta: { title: "工单信息管理" },
      },
    ],
  },
  {
    path: "/feedBackManage",
    component: layout,
    meta: { title: "意见反馈管理" },
    children: [
      {
        path: "/feedBackInfoManage",
        component: () =>
          import(
            "@/views/customerServiceManagement/feedBackManage/feedBackInfoManage/index"
          ),
        meta: { title: "意见反馈管理" },
      },
    ],
  },
  {
    path: "/informationManagement",
    component: layout,
    meta: { title: "信息管理" },
    children: [
      {
        path: "/consultRemarkManage",
        component: () =>
          import(
            "@/views/customerServiceManagement/informationManagement/consultRemarkManage/index"
          ),
        meta: { title: "咨询记录管理" },
      },
      {
        path: "/helpCenterManage",
        component: () =>
          import(
            "@/views/customerServiceManagement/informationManagement/helpCenterManage/index"
          ),
        meta: { title: "帮助中心管理" },
      },
      {
        path: "/officialWebManage",
        component: () =>
          import(
            "@/views/customerServiceManagement/informationManagement/officialWebManage/index"
          ),
        meta: { title: "官网公告管理" },
      },
    ],
  },
  {
    path: "/lotVideoManage",
    component: layout,
    meta: { title: "停车场视频监控" },
    children: [
      {
        path: "/realTimeVideoMonitor",
        component: () =>
          import(
            "@/views/videoManage/lotVideoManage/realTimeVideoMonitor/index"
          ),
        meta: { title: "实时视频" },
      },
      {
        path: "/historyVideoMonitor",
        component: () =>
          import(
            "@/views/videoManage/lotVideoManage/historyVideoMonitor/index"
          ),
        meta: { title: "历史视频" },
      },
    ],
  },
  // 财务管理系统
  {
    path: "/onroadFinancialManagement",
    component: layout,
    meta: { title: "财务管理" },
    children: [
      {
        path: "/onroadPayOrder",
        component: () =>
          import(
            "@/views/finanicalManaSystem/onroadFinancialManagement/onroadPayOrder/index"
          ),
        meta: { title: "支付订单" },
      },
      {
        path: "/onroadPattern",
        component: () =>
          import(
            "@/views/finanicalManaSystem/onroadFinancialManagement/onroadPattern/index"
          ),
        meta: { title: "支付对账" },
      },
      {
        path: "/onroadPatternDetails",
        component: () =>
          import(
            "@/views/finanicalManaSystem/onroadFinancialManagement/onroadPattern/onroadPatternDetails/index"
          ),
        meta: { title: "支付对账详情" },
      },
    ],
  },
  {
    path: "/accountManage",
    component: layout,
    meta: { title: "财务管理" },
    children: [
      {
        path: "/accPayCheck",
        component: () =>
          import("@/views/finanicalManaSystem/accountManage/accPayCheck/index"),
        meta: { title: "支付对账" },
      },
      {
        path: "/accPayRecord",
        component: () =>
          import(
            "@/views/finanicalManaSystem/accountManage/accPayRecord/index"
          ),
        meta: { title: "支付记录" },
      },
      {
        path: "/parkChargeStatistics",
        component: () =>
          import(
            "@/views/finanicalManaSystem/accountManage/parkChargeStatistics/index"
          ),
        meta: { title: "停车收费统计" },
      },
      {
        path: "/newEnergyDifference",
        component: () =>
          import(
            "@/views/finanicalManaSystem/accountManage/newEnergyDifference/index"
          ),
        meta: { title: "新能源差异" },
      },
    ],
  },
  {
    path: "/clearDisManage",
    component: layout,
    meta: { title: "清分管理" },
    children: [
      {
        path: "/clearDisConfig",
        component: () =>
          import(
            "@/views/finanicalManaSystem/clearDisManage/clearDisConfig/index"
          ),
        meta: { title: "清分配置" },
      },
      {
        path: "/clearDisRecord",
        component: () =>
          import(
            "@/views/finanicalManaSystem/clearDisManage/clearDisRecord/index"
          ),
        meta: { title: "清分记录" },
      },
    ],
  },
  {
    path: "/finInvoiceRecordMana",
    component: layout,
    meta: { title: "开票记录管理" },
    children: [
      {
        path: "/finInvoiceRecord",
        component: () =>
          import(
            "@/views/finanicalManaSystem/finInvoiceRecordMana/finInvoiceRecord/index"
          ),
        meta: { title: "开票记录" },
      },
      {
        path: "/reInvoiceRecord",
        component: () =>
          import(
            "@/views/finanicalManaSystem/finInvoiceRecordMana/reInvoiceRecord/index"
          ),
        meta: { title: "重新开票" },
      },
    ],
  },
  {
    path: "/reFundManagement",
    component: layout,
    meta: { title: "退款记录" },
    children: [
      {
        path: "/reFundManagement",
        component: () =>
          import("@/views/finanicalManaSystem/reFundManagement/index"),
        meta: { title: "退款记录" },
      },
    ],
  },
  {
    path: "/smsMessages",
    component: layout,
    meta: { title: "短信信息" },
    children: [
      {
        path: "/smsMessages",
        component: () =>
          import("@/views/finanicalManaSystem/smsMessages/index"),
        meta: { title: "短信信息" },
      },
    ],
  },
  {
    path: "/finSettleManage",
    component: layout,
    meta: { title: "结算管理" },
    children: [
      {
        path: "/finSettleConfig",
        component: () =>
          import(
            "@/views/finanicalManaSystem/finSettleManage/finSettleConfig/index"
          ),
        meta: { title: "结算配置" },
      },
      {
        path: "/finSettleRecord",
        component: () =>
          import(
            "@/views/finanicalManaSystem/finSettleManage/finSettleRecord/index"
          ),
        meta: { title: "结算记录" },
      },
    ],
  },
  {
    path: "/finStatisticState",
    component: layout,
    meta: { title: "统计报表" },
    children: [
      {
        path: "/finOperatStatisticsAnalyze",
        component: () =>
          import(
            "@/views/finanicalManaSystem/finStatisticState/finOperatStatisticsAnalyze/index"
          ),
        meta: { title: "运营统计分析" },
      },
      {
        path: "/incomeStatisticsAnalyze",
        component: () =>
          import(
            "@/views/finanicalManaSystem/finStatisticState/incomeStatisticsAnalyze/index"
          ),
        meta: { title: "收费统计分析" },
      },
    ],
  },
  // 诱导屏发布管理
  {
    path: "/screenManagement",
    component: layout,
    meta: { title: "统计报表" },
    children: [
      {
        path: "/screenMap",
        component: () => import("@/views/screenManagement/screenMap/index"),
        meta: { title: "运营统计分析" },
      },
    ],
  },
  {
    path: "/screenIssueMana",
    component: layout,
    meta: { title: "统计报表" },
    children: [
      {
        path: "/stairScreen",
        component: () =>
          import("@/views/screenManagement/screenIssueMana/stairScreen/index"),
        meta: { title: "一级诱导屏" },
      },
      {
        path: "/secondScreen",
        component: () =>
          import("@/views/screenManagement/screenIssueMana/secondScreen/index"),
        meta: { title: "二级诱导屏" },
      },
      {
        path: "/thirdScreen",
        component: () =>
          import("@/views/screenManagement/screenIssueMana/thirdScreen/index"),
        meta: { title: "三级诱导屏" },
      },
    ],
  },
  // 大数据分析
  {
    path: "/hadoopManagement",
    component: layout,
    meta: { title: "统计报表" },
    children: [
      {
        path: "/parkingOperationAnalysis",
        component: () =>
          import("@/views/hadoopManagement/parkingOperationAnalysis/index"),
        meta: { title: "停车运营分析" },
      },
      {
        path: "/abnormalVehicleAnalysis",
        component: () =>
          import("@/views/hadoopManagement/abnormalVehicleAnalysis/index"),
        meta: { title: "异常车辆分析" },
      },
      {
        path: "/equipmentIndexAnalysis",
        component: () =>
          import("@/views/hadoopManagement/equipmentIndexAnalysis/index"),
        meta: { title: "设备指标分析" },
      },
    ],
  },
  // 统计分析
  {
    path: "/statisticAnalysisManagement",
    component: layout,
    meta: { title: "统计分析" },
    children: [
      {
        path: "/incomeOnAFeeBasis",
        component: () =>
          import("@/views/statisticAnalysisManagement/incomeOnAFeeBasis/index"),
        meta: { title: "按收费方式收益" },
      },
      {
        path: "/overallIncomeAnalysis",
        component: () =>
          import(
            "@/views/statisticAnalysisManagement/overallIncomeAnalysis/index"
          ),
        meta: { title: "整体收益分析" },
      },
      {
        path: "/segmentIncomeAnalysis",
        component: () =>
          import(
            "@/views/statisticAnalysisManagement/segmentIncomeAnalysis/index"
          ),
        meta: { title: "路段收益分析" },
      },
    ],
  },
];

const createRouter = () =>
  new VueRouter({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;
