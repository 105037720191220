/*
 * @Description: 系统管理-app管理-资讯管理 informationManagement
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-06 10:33:00
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = '/operate'

// 资讯管理--查询
export function queryContentList (param) {
  return request({
    url: prefix + "/ContentController/queryContentList",
    method: "post",
    data: param
  });
}


// 资讯管理--新增
export function addContent (param) {
  return request({
    url: prefix + "/ContentController/addContent",
    method: "post",
    data: param
  });
}

// 资讯管理--修改
export function updateContent (param) {
  return request({
    url: prefix + "/ContentController/updateContent",
    method: "post",
    data: param
  });
}

//资讯管理--删除
export function delAdvertise (param) {
  return request({
    url: prefix + "/ContentController/delContent",
    method: "post",
    data: param
  });
}

