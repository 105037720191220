/*
 * @Description:车行道停车运营管理 运营管理 欠费记录 lackRecords
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 22:12:12
 * @LastEditors: zhoucheng
 */
import request from "@/utils/request";

const prefix = "/operate"

//查询表格数据接口
export function queryListByPage (param) {
  return request({
    url: prefix + "/order/getArrangeRecordIn",
    method: "post",
    data: param
  });
}
